import { Component, OnInit, Input, forwardRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LookupCriteria, LookupItem } from '../grid-v2/LookupItem';

export const EPANDED_DROPDOWN_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropDownComponent),
    multi: true,
};

@Component({
    selector: 'app-drop-down',
    templateUrl: './drop-down.component.html',
    styleUrls: ['./drop-down.component.css'],
    providers: [EPANDED_DROPDOWN_VALUE_ACCESSOR]
})
export class DropDownComponent implements ControlValueAccessor, OnInit {

    //#region VARIABLES

    onChange;

    public lookupCriteria: LookupCriteria;
    public source: LookupItem[];  // Niz orginalnih podatkov
    public data: LookupItem[];  // Spremenljiv niz podatkov, uporabljen pri filtriranju
    private initDataRecieved: boolean = false;
    private initDataCount: number = 0;

    private selectedValue: any;
    public selectedItem: LookupItem = undefined;

    public showSearchDialog: boolean = false; //Ali je prikazan dialog za razširjeno iskanje
    public popupSettings: string = "{}";

    // Vrednost po kateri pridobim pravilne podatke iz strežnika
    private lookupSource: string;
    @Input('LookupSource') set LookupSource(value: string) {
        if (value !== undefined) {
            // Nastavim vrednost po kateri pridobim podatke iz strežnika
            this.lookupSource = value;
        }
    }

    @Input('SelectedValue') set SelectedValue(value: string) {
        if (value != undefined) {
            console.log(value);
            //
            this.selectedValue = value;
            if (this.data != undefined) {
                this.selectedItem = this.data.find(x => x.value == this.SelectedValue);
            }
        }
    }

    /**Fiksno podani podatki za dropdown */
    public inputData: LookupItem[];
    @Input('Data') set Data(value: LookupItem[]) {
        if (value != this.inputData) {
            this.inputData = value;
            this.getLookupItems(undefined);
        }

    }

    @Input() LookupSourceParam: any = undefined;
    @Input() IsSearchMoreVisible: boolean = true;
    @Input() LookUpItemsLimit?: number = undefined;
    @Input() ForceServerSearch?: boolean = false;
    @Input() Filterable: boolean = false;
    @Input() ReadOnly: boolean = false;
    /**0 combobox, 1 listbox*/
    @Input() DropDownStyle: number = 0;

    @Input() tabIndexV: number = undefined;

    @Input() placeholder: string;
    @Output() dropdownEvents: EventEmitter<string> = new EventEmitter();
    @Output() LookupSelectedItemChanged = new EventEmitter<string>();

    //#region spremenljivke za popup z več kolonami

    /**Polje, ki ga bomo našolnili z izbranim elementom iz tabele header template */
    public multipleColumnsData: LookupItem[] = undefined;

    @Input() public popupSettingsDropdown?: string;
    @Input() MultipleColumnsDropdown: boolean = false;
    @Input() Columns?: string[] = [];
    //za prikaz izbrane vrstice v gridu
    public gridSelection: string[] = [];
    //#endregion

    @Input() showClearButton?: boolean = false;
    @Input() NotVisibleIfOnlyOneItem?: boolean = false;


    @ViewChild('gridCombobox', { static: false }) public gridCombobox: any;

    //#endregion VARIABLES

    constructor() { }

    ngOnInit() {
        this.selectedItem = new LookupItem();
        this.getLookupItems(undefined);

    }

    private getLookupItems(filter: string) {
        if (this.inputData == undefined) //če niso podane fiksne vrednosti
        {
            this.lookupCriteria = new LookupCriteria(this.lookupSource);
            this.lookupCriteria.contentFilter = filter;
            this.lookupCriteria.limit = this.LookUpItemsLimit;
            this.lookupCriteria.param = this.LookupSourceParam != undefined && this.LookupSourceParam != "" ? this.LookupSourceParam : undefined;
        }
        else //podane so fiksne vrednosti
        {
            this.source = this.inputData;
            this.data = this.inputData;

            // Po pridobljenih podatkih iz strežnika še izbranemu predmetu nastavim ostale vrednosti
            //this.selectedItem = this.source.find(x => x.value === this.selectedItem.value);
        }
    }

    // Proži ob spremembi tesktovne vrednosti v drop-downu
    public async filterChangeHandler(filter: any) {
        //Podan je parameter, da gre vedno na server po podatke
        if (this.ForceServerSearch == true) {
            this.getLookupItems(filter);
            return;
        }

        // Filtriram podatke
        if (this.LookUpItemsLimit == undefined)//Če ni podan max št. zapisov, potem so vedno vsi na klientu in ni potrebe po izvajanju API klica
        {
            this.data = this.source.filter((s) => s.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
        }
        else if (this.LookUpItemsLimit > this.initDataCount) {
            this.data = this.source.filter((s) => s.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
        }
        else {
            this.getLookupItems(filter);
        }
    }

    public selectionChangeHandler($event) {
        if ($event == undefined) {
            this.onChange();
        }
        else if ($event.value !== undefined) {
            this.onChange($event.value);
        }
        else {
            this.onChange();
        }
    }

    public multipleColumnsDropdownSelectionChangeHandler(selectedItem: LookupItem): void {
        this.selectedItem = this.data.filter(x => x.value == selectedItem.value)[0];
        this.multipleColumnsData = [this.selectedItem];

        if (this.selectedItem != undefined) {
            this.selectionChangeHandler(this.selectedItem);
            this.LookupSelectedItemChanged.emit(this.selectedItem.value);//Vrnem value v callback
        }

        //če uporabljamo advanced search - combobox z gridom - moramo programsko zapirati dropdown seznam
        this.gridCombobox.toggle(false);
    }

    public focusChange(event) {
        //this.dropdownEvents.emit('focus');
    }
    public blurChange(event) {
        //this.dropdownEvents.emit('blur');
    }

    //#region CONTORL-VALUE-ACCESSOR funkcije

    public writeValue(value: any) {
        this.selectedItem = new LookupItem();
        if (value === undefined) {
            this.selectedItem.value = '';
        } else {
            // Podano vrednost nastavim kot vrednost izbranega predmeta
            this.selectedItem.value = value;
        }
    }

    public registerOnChange(fn: any) {
        this.onChange = fn;
    }

    public registerOnTouched() {

    }

    //funkcija se kliče, ko uporabnik spremeni izbiro
    public lookupSelectedItemChanged(value: any): void {
        //pokliče se funkcija v parent kontrolo, ki je nastavljena za ta event
        this.LookupSelectedItemChanged.emit(value);
    }
    //#endregion CONTORL-VALUE-ACCESSOR

    public showExtendedSearchDialog() {
        this.lookupCriteria.limit = this.LookUpItemsLimit;
        this.showSearchDialog = true;
    }

    public hideExtendedSearchDialog() {
        this.showSearchDialog = false;
    }

    /**
     * Funkcija se kliče, ko iz razširjenega iskanja pridobimo rezultat
     * @param value 
     */
    public searchDialogItemChanged(value: any) {
        this.selectedItem = value;
        this.selectionChangeHandler(undefined);
        this.LookupSelectedItemChanged.emit(value);
        this.hideExtendedSearchDialog();
    }
}
