import { FormComponentView } from "../../Core/Components/form-with-labels-v2/FormComponentView";

export const UnitsFormSettings: FormComponentView = {
    ComponentName: 'UnitForm',
    ComponentType: 1,
    Tabs: [
        {
            TabName: '',
            Groups: [
                {
                    GroupName: '',
                    CSSStyle: 'width: inherit; grid-template-columns: 1fr;',
                    Fields: [
                        { FieldName: 'busUnitName', FieldType: 'input', FieldRequired: true, FieldEditable: true },
                        { FieldName: 'active', FieldType: 'checkbox' }
                    ]
                }
            ]
        }
    ],
    Add: false,
    Edit: true,
    Delete: false,
    CommandsVisible: true,
    DisplayType: 1
}