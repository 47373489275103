import { Component, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

export let browserRefresh = false;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent implements OnDestroy {

	subscription: Subscription;

	constructor(private router: Router) {
		this.subscription = router.events.subscribe(e => {
			if (e instanceof NavigationStart) {
				browserRefresh = !router.navigated;
			}
		});
	}

	ngOnDestroy(): void {}
}
