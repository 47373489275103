import { FormGroup } from "@angular/forms";
import { ComponentViewDTO } from "../../Common/ComponentViewDTO";

export class FormComponentView extends ComponentViewDTO {
    /** Podatkovni predmet iz katerega pridobim vrednosti in sestavim FormGroup */
    dataItem?: any;
    /** Podan FormGroup, ki že obstaja v staršu   */
    formGroup?: FormGroup;
    /** Validacijske napake */
    validationErrors?: any[];
    CommandsVisible?: boolean;

}

/**
 * Zaklene vsa polja
 * @param formCV
 */
export function lockFormFields(formCV: FormComponentView, fields?: string[]): FormComponentView {
    let lockedFormCV: FormComponentView = Object.assign({}, formCV);
    for (let tab of lockedFormCV.Tabs) {
        for (let group of tab.Groups) {
            for (let field of group.Fields) {
                if (fields == undefined)
                    field.FieldEditable = false;
                else {
                    if (fields.filter(x => x == field.FieldName).length > 0)
                        field.FieldEditable = false;
                }
            }

        }
    }
    return lockedFormCV;
}

/**
 * Zaklene skupino polj
 * @param formCV
 * @param groupName
 */
export function lockFormGroupFields(formCV: FormComponentView, groupName: string): FormComponentView {
    for (let tab of formCV.Tabs) {
        for (let group of tab.Groups) {
            if (group.GroupName == groupName) {
                for (let field of group.Fields)
                    field.FieldEditable = false;
            }

        }
    }
    return formCV;
}

export function getFormComponentViewFieldByName(formCV: FormComponentView, fieldName: string) {
    for (let tab of formCV.Tabs) {
        for (let group of tab.Groups) {
            for (let field of group.Fields) {
                if (field.FieldName == fieldName) {
                    return field;
                }
            }
        }
    }

    return undefined;
}

export function getFormComponentViewFieldByLabelName(formCV: FormComponentView, fieldLabelName: string) {
    for (let tab of formCV.Tabs) {
        for (let group of tab.Groups) {
            for (let field of group.Fields) {
                if (field.FieldLabel == fieldLabelName) {
                    return field;
                }
            }
        }
    }

    return undefined;
}

export function showHideFormComponentField(formCV: FormComponentView, fieldName: string, fieldVisible: boolean) {
    for (let tab of formCV.Tabs) {
        for (let group of tab.Groups) {
            for (let field of group.Fields) {
                if (field.FieldName == fieldName) {
                    field.FieldVisible = fieldVisible;
                }
            }
        }
    }
}

/**
 * zaklene polja za urejanje v formi
 * @param formCV
 * @param fieldNames imena polj, ki se bodo zaklenila
 */
export function setFormComponentViewFieldsToReadonly(formCV: FormComponentView, fieldNames: string[]) {
    for (let tab of formCV.Tabs) {
        for (let group of tab.Groups) {
            for (let field of group.Fields) {
                if (fieldNames.includes(field.FieldName) == true) {
                    field.FieldEditable = false;
                }
            }
        }
    }
}

/**
 * Odklene polja v formi za urejanje
 * @param formCV
 * @param fieldNames
 */
export function setFormComponentViewFieldsToEditable(formCV: FormComponentView, fieldNames: string[]) {
    for (let tab of formCV.Tabs) {
        for (let group of tab.Groups) {
            for (let field of group.Fields) {
                if (fieldNames.includes(field.FieldName) == true) {
                    field.FieldEditable = true;
                }
            }
        }
    }
}