import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { dtoUser } from '../../common/dtos/dtoUser';
import { Router } from '@angular/router';
import { getAPIUrl, LoginServiceEndpoint } from '../../global';
import { Observable, of } from 'rxjs';
import { NavigationService } from '../navigation-services/navigation.service';
import { ThrowStmt } from '@angular/compiler';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    public user: dtoUser = undefined;
    /**Objekt, v katerega se shranijo globalnee vrednosti, ki se ob vsakem klicu API-ja poleg po�ljejo v headerju (uporabljen tudi v aplikacijskem delu)*/
    public headerParams: any = {};

    constructor(private http: HttpClient, private navigationService: NavigationService) { }

    public setLoggedInUser(u: dtoUser) {       
        this.user = u;
        let loginData: string = "Basic " + btoa(this.user.userName + ":" + this.user.password + ":" + this.user.isAdmin);

        sessionStorage.setItem('loginData', loginData);

        if (localStorage.getItem('auto_login')) {
            localStorage.setItem('loginData', loginData);
        }
    }

    public isAuthenticated(): boolean {        
        if (this.user != null) return true;
        return false;
    }

    doLogin(username: string, password: string) {
        let headerContent: any = {};
        headerContent['Content-Type'] = 'application/json';
        headerContent['Access-Control-Allow-Origin'] = '*';

        return this.http.post<dtoUser>(getAPIUrl(LoginServiceEndpoint.login), { 'UserName': username, 'Password': password }, headerContent);
    }

    isAdmin() {
        var session = sessionStorage.getItem('loginData');
        var data = atob(session.replace("Basic ", "")).split(":");

        return data[2] == "true";
    }

    doLoginFromSessionStorage() {
        return new Promise<boolean>(async (resolve, reject) => {
            if (sessionStorage.getItem('loginData') != undefined) {
                let headerContent: any = {};
                headerContent['Content-Type'] = 'application/json';
                headerContent['Access-Control-Allow-Origin'] = '*';

                var session = sessionStorage.getItem('loginData');
                var data = atob(session.replace("Basic ", "")).split(":");

                var userName = data[0];
                var password = data[1];

                await this.http.post<dtoUser>(getAPIUrl(LoginServiceEndpoint.login), { 'UserName': userName, 'Password': password }, headerContent).toPromise()
                    .then(_loginData => {
                        var user: dtoUser = new dtoUser();
                        user.firmId = _loginData['firmId'];
                        user.password = password;
                        user.token = _loginData['token'];
                        user.tokenValidTo = _loginData['tokenValidTo'];
                        user.userId = _loginData['userId'];
                        user.userName = userName;
                        user.isAdmin = _loginData['isAdmin']
                        user.firmName = _loginData['firmName'];

                        this.setLoggedInUser(user);
                        resolve(true);
                    })
                    .catch((err: any) => {
                        console.log(err);
                        this.navigationService.navigateToUrl('login');
                        resolve(false);
                    });
            }
            else {
                this.navigationService.navigateToUrl('login');
                resolve(false);
            }
        })
        .catch(res => {
            console.log("V func: " + res);
            return false;
        });

        //return promise;
    }

    generateHeader() {
        let headerContent: any = {};
        headerContent['Content-Type'] = 'application/json';
        headerContent['Authorization'] = "Bearer " + this.user.token;

        return { headers: headerContent };
    }

    async doLogOut() {
        this.user = null;
        sessionStorage.removeItem("loginData");
        localStorage.removeItem('loginData');
        localStorage.removeItem('licensed');
        localStorage.removeItem('firm');

        await new Promise(f => setTimeout(f, 300));

        this.navigationService.navigateToUrl('login');
    }

    public handleError<T>(operation = 'operation', result?: T) {
        return (errorResponse: any): Observable<T> => {
            console.error(errorResponse); // log to console instead
            if (errorResponse instanceof HttpErrorResponse) {
                //Akcije
                if (errorResponse.status == 404) //Not found - reddirect to login
                {
                    this.navigationService.navigateToUrl('login');
                    return of(result as T);
                }
                else //prikazem samo error
                {
                    //this.loadingService.busyNo();
                    console.log(errorResponse);
                }

            }
            else {
                console.log(errorResponse);
            }

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
