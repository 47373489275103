import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  /**Spremenljivka, ki definira loading bar */
  private isBusy: boolean = false;

  constructor() { }
  /**Uporabi se ngx-loading, ki je dodan tudi v base component. */
  public busyYes() {
    this.isBusy = true;
  }
  public busyNo() {
    this.isBusy = false;
  }
  public IsBusy(): boolean {
    return this.isBusy;
  }
}
