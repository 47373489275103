import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateMS'
})
export class DateMSPipe implements PipeTransform {

  transform(dateMS: string): Date {
    return moment.utc(dateMS).toDate();
  }

}
