import 'hammerjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './appComponents/login/login.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';
import { AppConfig } from './Core/CoreServices/configuration-services/configuration.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { LoginService } from './services/loginService/login.service';
import { NavigationService } from './services/navigation-services/navigation.service';
import { MessageService } from './Core/CoreServices/message-services/message.service';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DialogsModule, DialogModule } from '@progress/kendo-angular-dialog';
import { FormWithLabelsV2Component } from './Core/Components/form-with-labels-v2/form-with-labels-v2.component';
import { GridV2Component } from './Core/Components/grid-v2/grid-v2.component';
import { LocalStorageService } from './Core/CoreServices/localStorage-services/local-storage.service';
import { SettingService } from './Core/CoreServices/setting-services/setting.service';
import { LayoutModule } from '@angular/cdk/layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { GridV2FieldSelectionDialogComponent } from './Core/Components/grid-v2/grid-v2-field-selection-dialog/grid-v2-field-selection-dialog.component';
import { CallbackPipe } from './Core/Common/Pipes/callBackPipe';
import { LoadingService } from './Core/CoreServices/loading-services/loading.service';
import { NgxLoadingModule } from 'ngx-loading';
import { DropDownComponent } from './Core/Components/drop-down/drop-down.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DocumentsComponent } from './appComponents/documents/documents.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { MsgDialogComponent } from './Core/Components/msg-dialog/msg-dialog.component';
import { ToastrModule } from 'ngx-toastr';
import { LayoutModule as AngularLayoutModule } from '@progress/kendo-angular-layout';
import { DateMSPipe } from './Core/Common/Pipes/date-ms.pipe';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { WebStorageModule } from 'ngx-store';
import { AllDevicesComponent } from './appComponents/all-devices/all-devices.component';
import { UsersComponent } from './appComponents/users/users.component';
import { SettingsComponent } from './appComponents/settings/settings.component';
import { BusUnitsComponent } from './appComponents/bus-units/bus-units.component';
import { PostponedDocumentsComponent } from './appComponents/postponed-documents/postponed-documents.component';


//#region Init Functions
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeApp(appConfig: AppConfig) {
    return () => appConfig.load();
}

//#endregion

@NgModule({
    imports: [
        HttpModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        AppRoutingModule,
        FormsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'sl',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatRippleModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        GridModule,
        PDFModule,
        ExcelModule,
        LayoutModule,
        ButtonsModule,
        InputsModule,
        DateInputsModule,
        DialogsModule,
        PopupModule,
        DropDownsModule,
        NgxLoadingModule,
        DialogModule,
        ToastrModule.forRoot({
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-bottom-right'

        }),
        AngularLayoutModule,
        ChartsModule,
        WebStorageModule.forRoot()
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        LoginComponent,
        FormWithLabelsV2Component,
        GridV2Component,
        GridV2FieldSelectionDialogComponent,
        DropDownComponent,
        MsgDialogComponent,
        CallbackPipe,
        DocumentsComponent,
        NavbarComponent,
        SidebarComponent,
        DateMSPipe,
        AllDevicesComponent,
        UsersComponent,
        SettingsComponent,
        BusUnitsComponent,
        PostponedDocumentsComponent

    ],
    providers: [
        LoginService,
        NavigationService,
        MessageService,
        TranslateService,
        LocalStorageService,
        SettingService,
        LoadingService,
        AppConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfig], multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
