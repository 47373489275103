export class dtoUser {
    constructor() {
        this.userName = this.firmId = this.firmName = this.userId = this.token = this.tokenValidTo = this.isAdmin = undefined;
    }

    userName: string;
    password: string;
    firmId: number;
    firmName: string;
    userId: number;
    token: string;
    tokenValidTo: Date;
    isAdmin: boolean;
}
