import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoginService } from '../loginService/login.service';
import { getAPIUrl, getAPIUrlWQueryParams, DeviceServiceEndpoint, DocumentServiceEndpoint, FirmSignDeviceServiceEndpoint, UserServiceEndpoint, LicenseServiceEndpoint, BusUnitEndpoint } from '../../global';
import { dtoFirmDevice } from 'app/common/dtos/dtoFirmDevice';
import { dtoSignDevice } from 'app/common/dtos/dtoSignDevice';
import { dtoDocument } from 'app/common/dtos/dtoDocument';
import { FirmSignDevice } from 'app/common/objects/firmSignDevice';
import { dtoFirmSignDevice } from 'app/common/dtos/dtoFirmSignDevice';
import { dtoUsers } from 'app/common/dtos/dtoUsers';
import { Verification } from 'app/common/objects/verification';
import { dtoLicense } from 'app/common/dtos/dtoLicense';
import { dtoBusUnit } from 'app/common/dtos/dtoBusUnit';

@Injectable({
    providedIn: 'root'
})
export class DataEditService {

    constructor(private http: HttpClient, private router: Router, private loginService: LoginService) { }

    //DEVICES
    getAllFirmDevices() {
        return this.http.get<dtoFirmDevice[]>(getAPIUrl(DeviceServiceEndpoint.getAllFirmDevices), this.loginService.generateHeader());
    }

    getAllSignDevices() {
        return this.http.get<dtoSignDevice[]>(getAPIUrl(DeviceServiceEndpoint.getAllSignDevices), this.loginService.generateHeader());
    }

    getAllSignDevicesInBusUnit() {
        return this.http.get<dtoSignDevice[]>(getAPIUrl(DeviceServiceEndpoint.getAllSignDevicesInBusUnit), this.loginService.generateHeader());
    }

    postNewFirmDevice(newDevice: dtoFirmDevice) {
        return this.http.post(getAPIUrl(DeviceServiceEndpoint.postFirmDevice), JSON.stringify(newDevice), this.loginService.generateHeader());
    }

    deleteFirmDevice(id_firmDevice: number) {
        return this.http.delete(getAPIUrl(DeviceServiceEndpoint.deleteFirmDevice, [String(id_firmDevice)]), this.loginService.generateHeader());
    }

    deleteSignDevice(id_signDevice: number) {
        return this.http.delete(getAPIUrl(DeviceServiceEndpoint.deleteSignDevice, [String(id_signDevice)]), this.loginService.generateHeader());
    }

    getDeviceById(id: any) {
        return this.http.get<any>(getAPIUrl(DeviceServiceEndpoint.device, [String(id)]), this.loginService.generateHeader());
    }

    updateFirmDevice(device: dtoFirmDevice) {        
        return this.http.put<any>(getAPIUrl(DeviceServiceEndpoint.updateFirmDevice, [String(device.firmDeviceId)]), JSON.stringify(device), this.loginService.generateHeader());
    }

    updateSignDevice(device: dtoSignDevice) {
        return this.http.put<any>(getAPIUrl(DeviceServiceEndpoint.updateSignDevice, [String(device.rowGuidSignDevice)]), JSON.stringify(device), this.loginService.generateHeader());
    }


    //FIRM SIGN DEVICES
    getAllFirmSignDevices() {
        return this.http.get<dtoFirmSignDevice[]>(getAPIUrl(FirmSignDeviceServiceEndpoint.firmSignDevices), this.loginService.generateHeader());
    }

    createFirmSignDevice(firmSignDevice: FirmSignDevice) {
        return this.http.post(getAPIUrl(FirmSignDeviceServiceEndpoint.firmSignDevices), JSON.stringify(firmSignDevice), this.loginService.generateHeader());
    }

    getFirmSignDeviceByID(id_firmSignDevice: number) {
        return this.http.get<dtoFirmDevice>(getAPIUrl(FirmSignDeviceServiceEndpoint.firmSignDevices, [String(id_firmSignDevice)]), this.loginService.generateHeader());
    }

    updateFirmSignDevice(id_firmSignDevice: number, updateFirmSignDevice: FirmSignDevice) {
        return this.http.put(getAPIUrl(FirmSignDeviceServiceEndpoint.firmSignDevices, [String(id_firmSignDevice)]), JSON.stringify(updateFirmSignDevice), this.loginService.generateHeader());
    }

    deleteFirmSignDevice(id_firmSignDevice: number) {
        return this.http.delete(getAPIUrl(FirmSignDeviceServiceEndpoint.firmSignDevices, [String(id_firmSignDevice)]), this.loginService.generateHeader());
    }


    //DOCUMENTS
    getDocumentStatus(erp_key: string) {
        return this.http.get<dtoDocument>(getAPIUrlWQueryParams(DocumentServiceEndpoint.getDocumentStatus, [{ name: 'erpkey', value: erp_key }]), this.loginService.generateHeader());
    }

    getDocumentById(id_doc: number) {
        return this.http.get<dtoDocument>(getAPIUrl(DocumentServiceEndpoint.getDocumentById, [String(id_doc)]), this.loginService.generateHeader());
    }

    getAllDocuments() {
        return this.http.get<dtoDocument[]>(getAPIUrl(DocumentServiceEndpoint.getAllDocuments), this.loginService.generateHeader());
    }

    deleteDocument(id_doc: number) {
        return this.http.delete(getAPIUrl(DocumentServiceEndpoint.getDocument, [String(id_doc)]), this.loginService.generateHeader());
    }

    getDeviceNamesForFilter() {
        return this.http.get<any>(getAPIUrl(DocumentServiceEndpoint.getDeviceNames), this.loginService.generateHeader());
    }

    getPostponedDocumentsInBranch() {
        return this.http.get<dtoDocument[]>(getAPIUrl(DocumentServiceEndpoint.getPostponedDocs), this.loginService.generateHeader());
    }

    getPostponedFilterData() {
        return this.http.get<any>(getAPIUrl(DocumentServiceEndpoint.getDataForFilter), this.loginService.generateHeader());
    }

    sendPostponedDocument(guid: string, doc: dtoDocument) {
        return this.http.put<any>(getAPIUrl(DocumentServiceEndpoint.sendPostponedDocument, [String(guid)]), JSON.stringify(doc), this.loginService.generateHeader());
    }


    //USERS
    getAllUsers() {
        return this.http.get<dtoUsers[]>(getAPIUrl(UserServiceEndpoint.user), this.loginService.generateHeader());
    }

    createNewUser(newUser: dtoUsers) {
        return this.http.post(getAPIUrl(UserServiceEndpoint.user), JSON.stringify(newUser), this.loginService.generateHeader());
    }

    getUserById(id_user: number) {
        return this.http.get<dtoUsers>(getAPIUrl(UserServiceEndpoint.user, [String(id_user)]), this.loginService.generateHeader());
    }

    updateUser(user: dtoUsers) {
        return this.http.put<any>(getAPIUrl(UserServiceEndpoint.user, [String(user.userId)]), JSON.stringify(user), this.loginService.generateHeader());
    }

    deleteUser(id_user: number) {
        return this.http.delete(getAPIUrl(UserServiceEndpoint.user, [String(id_user)]), this.loginService.generateHeader());
    }


    //LICENSE
    registerLicense(licKey: string) {
        return this.http.post(getAPIUrl(LicenseServiceEndpoint.license), JSON.stringify(licKey), this.loginService.generateHeader());
    }

    deactivateLicense() {
        return this.http.delete(getAPIUrl(LicenseServiceEndpoint.license), this.loginService.generateHeader());
    }

    getLicenseFromTaxNumber() {
        return this.http.get<dtoLicense>(getAPIUrl(LicenseServiceEndpoint.license), this.loginService.generateHeader());
    }

    checkLicense() {
        return this.http.get<any>(getAPIUrl(LicenseServiceEndpoint.checkLic), this.loginService.generateHeader());
    }

    deactivateSignDeviceLicense(guid: string) {
        return this.http.delete(getAPIUrl(LicenseServiceEndpoint.deactivateSignDeviceLicense, [guid]), this.loginService.generateHeader());
    }

    //BUS UNITS
    getUnitById(unit_id: number) {
        return this.http.get<dtoBusUnit>(getAPIUrl(BusUnitEndpoint.busUnit, [String(unit_id)]), this.loginService.generateHeader());
    }

    getAllBusUnits(active: boolean) {
        return this.http.get<dtoBusUnit[]>(getAPIUrl(BusUnitEndpoint.busUnitsForFirm, [String(active)]), this.loginService.generateHeader());
    }

    createNewUnit(newUnit: dtoBusUnit) {
        return this.http.post(getAPIUrl(BusUnitEndpoint.busUnit), JSON.stringify(newUnit), this.loginService.generateHeader());
    }

    updateUnit(unit: dtoBusUnit) {
        return this.http.put<any>(getAPIUrl(BusUnitEndpoint.busUnit, [String(unit.busUnitId)]), JSON.stringify(unit), this.loginService.generateHeader());
    }

    deleteBusUnit(id: number) {
        return this.http.delete(getAPIUrl(BusUnitEndpoint.busUnit, [String(id)]), this.loginService.generateHeader());
    }
}
