/**
 * Funkcija prever, če polje objektov vsebuje elemente
 */
 export function isNullOrEmpty(array: any[]): boolean {
    if (array == undefined) return true;
    if (array.length < 1) return true;

    return false;
}

export function isMobileMenu(): boolean {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
}