import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { getAPIUrl, getAPIUrlWQueryParams, SettingsAPIFunctions } from '../../../global';
import { BaseClass, ResponseMsgWCode } from '../../Common/BaseClass';
import { GridColumnClass } from '../../Components/grid-v2/gridColumnClass';
import { AppConfig } from '../configuration-services/configuration.service';
import { LoginService } from '../../../services/loginService/login.service';
import { of } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class SettingService {

    constructor(private http: HttpClient, private userService: LoginService) { }

    getUserProfileFirmSetting(): Observable<BaseClass> {
        return this.http.get<BaseClass>(getAPIUrl(SettingsAPIFunctions.userProfileRightSetting), this.userService.generateHeader())
            .pipe(catchError(this.userService.handleError('getUserProfileFirmSetting', null)));
    }

    getUserGridColumnSettings(gridEnum: string): Observable<GridColumnClass[]> {
        if (AppConfig.settings.onlyFrontEnd) {
            //Nastavitve dobim iz LocalStorage
            let columnSettings: GridColumnClass[] = [];
            let storageColumnSettings: any = localStorage.getItem(gridEnum);
            if (storageColumnSettings != undefined)
                columnSettings = JSON.parse(storageColumnSettings);
            return of(columnSettings)
        }
        else {
            return this.http.get<GridColumnClass[]>(getAPIUrlWQueryParams(SettingsAPIFunctions.userGridColumnSettings, [{ name: 'GridEnum', value: gridEnum }]), this.userService.generateHeader())
                .pipe(catchError(this.userService.handleError('getUserGridColumnSettings', [])));
        }
    }

    saveUserGridColumnSettings(gridEnum: string, data: GridColumnClass[]): Observable<ResponseMsgWCode> {
        if (AppConfig.settings.onlyFrontEnd) {
            localStorage.setItem(gridEnum, JSON.stringify(data));
            return of({ Success: true });
        }
        else {
            return this.http.post<ResponseMsgWCode>(getAPIUrlWQueryParams(SettingsAPIFunctions.userGridColumnSettings, [{ name: 'GridEnum', value: gridEnum }]), data, this.userService.generateHeader())
                .pipe(catchError(this.userService.handleError('saveUserGridColumnSettings', null)));
        }
    }
}