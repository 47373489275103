import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/loginService/login.service';
import { NavigationService } from '../../services/navigation-services/navigation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'app/Core/CoreServices/message-services/message.service';
import { LoadingService } from 'app/Core/CoreServices/loading-services/loading.service';
import { DataEditService } from 'app/services/dataEditService/data-edit.service';
import { AppConfig } from 'app/Core/CoreServices/configuration-services/configuration.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']

})
export class LoginComponent implements OnInit {
    public tronSignLoginData: any = { UserName: '', Password: '' };
    public loginErrorResponse: any = undefined;
    public urlDialogVisible: boolean = false;
    public currUrl: string;

    constructor(private service: DataEditService, private loginService: LoginService, private navigationService: NavigationService, private messageService: MessageService, private loadingService: LoadingService) { }

    ngOnInit() {
        this.currUrl = AppConfig.settings.apiServer.apiWebAddress;

        if (localStorage.getItem("api_url") != null && localStorage.getItem("api_url") != "") {
            this.currUrl = localStorage.getItem("api_url");
        }

        this.loginService.doLoginFromSessionStorage().then(resp => {
            if (localStorage.getItem('auto_login') != null && localStorage.getItem('auto_login') == "true" && localStorage.getItem('loginData') != null) {
                // avtomatski login če uporabnik vklopi nastavitev
                var session = localStorage.getItem('loginData');
                var data = atob(session.replace("Basic ", "")).split(":");

                this.tronSignLoginData.UserName = data[0];
                this.tronSignLoginData.Password = data[1];

                this.doLogin();
            }
        })
        .catch(err => {
            console.error(err);
        });
    }

    public async doLogin() {
        try {
            this.loadingService.busyYes();

            localStorage.setItem("api_url", this.currUrl); // če uporabnik nikoli ne gre v nastavitve

            let u: any = await this.loginService.doLogin(this.tronSignLoginData.UserName, this.tronSignLoginData.Password).toPromise();
            if (u != null) {
                u['password'] = this.tronSignLoginData.Password;

                this.loginService.setLoggedInUser(u);

                await this.service.checkLicense().toPromise().then((resp) => {
                    if (resp != null) {
                        localStorage.setItem('firm', u.firmId);
                        localStorage.setItem('licensed', "true");

                        if (this.loginService.isAdmin())
                            this.navigationService.navigateToUrlWQueryParams('devices');
                        else
                            this.navigationService.navigateToUrlWQueryParams('postponed');
                    } else {
                        localStorage.removeItem('licensed');
                        this.navigationService.navigateToUrlWQueryParams('settings');
                    }
                });
            }
            this.loadingService.busyNo();
        }
        catch (err) {
            if (err instanceof HttpErrorResponse) {
                //Akcije
                if (err.status == 0) {
                    this.loginErrorResponse = err;
                }
                if (err.status == 404) {
                    this.loginErrorResponse = err;
                }
                if (err.status == 403) {
                    this.loginErrorResponse = err;
                }
            }
        }
    }

    public showUrlDialog() {
        this.urlDialogVisible = true;
    }

    public closeDialog() {
        localStorage.setItem("api_url", this.currUrl);

        this.urlDialogVisible = false;
    }
}
