import { ComponentViewDTO, ComponentFieldDTO } from "../../Common/ComponentViewDTO";


export class GridComponentView extends ComponentViewDTO {
    /** enonivojsko podana polja - zaenkrat še ne podpiramo večnivojev */
    Fields: ComponentFieldDTO[] = [];


}

/**Prenos večnivojskega zapisa v 1 nivo */
export function copyTabGroupFieldsToFields(gridComponentView: GridComponentView): GridComponentView {

    gridComponentView.Fields = [];
    for (let tab of gridComponentView.Tabs) {
        for (let group of tab.Groups) {
            for (let field of group.Fields) {
                if (field.FieldType == 'numericInput')
                    field.FilterType = 'numeric';
                else if (field.FieldType == 'date')
                    field.FilterType = 'text';
                gridComponentView.Fields.push(field);

            }


        }
    }
    return gridComponentView;
}

export function setGridComponentViewToReadOnly(gridSettings: GridComponentView): GridComponentView {
    for (let field of gridSettings.Fields)
        field.FieldEditable = false;

    return gridSettings;
}
