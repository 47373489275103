export class ComponentCriteriaDTO {
    componentName?: string;
    componentType?: number;
    templateId?: number;
    firmId?: number;
    companyId?: number;
    userGroupId?: number;
    userId?: number
}

// Nastavitve tabele
export class ComponentViewDTO {

    constructor() { this.Edit = true; this.Add = true; this.Delete = true; this.EnableExport = true; this.isSelectable = true; this.Tabs = [] }

    /**Ključ zapisa - če je shranjen v DB*/
    id?: number;

    /** Tip generične komponente. 1-Grid, 2-Forma */
    ComponentType: number;
    /** Unikaten enum tabelaričnega pogleda */
    ComponentName: string;
    /** Pove če bo v tabeli prikazan gumb za dodajanje nove vrednosti, privzeto false */
    Add: boolean;
    /** Pove če bo v tabeli prikazan gumb za urejanje vrednosti, privzeto false */
    Edit: boolean;
    /** Pove če bota v tabeli prikazana gumba za brisanje in posodabljanje podatkov */
    Delete: boolean;
    /** Ime pod katerim bodo shranjene izvožene pdf in excel datoteke */
    DownloadFileName?: string;
    /**Pove, če sta v tabeli vidna gumba za export v excel ter pdf */
    EnableExport?: boolean;
    /**Ali je vrstice mogoče izbrati - če je false, potem se ne označujejo - default se označujejo*/
    isSelectable?: boolean;
    /**Ali je prikazan stolpec z checkboxi (tudi za izbiro vseh vrstic hkrati) */
    showCheckboxColumn?: boolean;
    /**Ali se uporabi paging - več zavihkov oz. strani **/
    pageable?: boolean;
    /**Višina komponente*/
    height?: number;
    ///** Niz stolpcev, ki bodo prikazani v tabeli */
    //Fields?: ComponentFieldDTO[];
    /** Skupine, ki bodo prikazani v tabeli */
    Tabs?: ComponentFieldTabDTO[];
    /**Ali je omogočen edit te komponente s strani uporabnika (default = NE)*/
    CanEditComponentView?: boolean;
    /**Tip prikaza 
     * (FORM) --> 0,undefined=oznaka in polje sta v isti vrstici, 1=Oznaka je nad poljem (2 vrstici)
     * (GRID) --> Še ni podprto*/
    DisplayType?: number;
    /** Tip premikanja po formi s tipko TAB
     * 0 --> Po vrstici
     * 1 --> Po stolpcu
     * */
    TabMoveType?: number;
    /**
     * false --> zahtevana potrditev
     * true --> brez potrjevanja
     */
    DeleteWOConfirmation?: boolean;

    /**Ali je v tabeli omogočeno grupiranje*/
    groupable?: boolean;

    //Struktura, za shranjevanje v bazo
    TemplateID?: number;
    FirmId?: number;
    CompanyId?: number;
    UserGroupId?: number;
    UserId?: number;
}

export class ComponentFieldTabDTO {
    constructor() { this.TabName = "", this.Groups = [] }
    id?: number;
    TabName: string;
    TabLabel?: string;
    SortID?: number;
    Groups: ComponentFieldGroupDTO[];
}

export class ComponentFieldGroupDTO {
    constructor() { this.GroupName = "", this.Fields = [] }
    id?: number;
    /**Ime skupine*/
    GroupName: string;
    /**Prikazan naziv skupine*/
    GroupLabel?: string;
    /**Definicija izgleda: št solpcev, tok dodajanja elementov...*/
    CSSStyle?: string;
    SortID?: number;
    /**Polja skupine*/
    Fields: ComponentFieldDTO[];
}


//Nastavitve posameznega stolpca
export class ComponentFieldDTO {
    constructor() {
        this.FieldType = "";
        this.FieldVisible = true;
        this.FieldEditable = true;
        this.FieldRequired = false;
    }
    id?: number;

    /** Nazv polja iz katerega so pridobljene vresnosti */
    FieldName: string;
    /** Tip celice:
        - input (tekstovno polje),
        - maskedInput (zamaskirano tekstovno polje - gesla)
        - numericInput (numerično polje)
        - numericIntegerInput (celoštevilno numerično polje)
        - checkbox,
        - dropdown (tekstovno polje s spustnim menijem),
        - date (datum)
        - dateTime (datum in čas)
        - time (čas)
        - dateMS (microsoft datum pretvori v js datum objekt)
        - dateTimeMS (microsoft datum pretvori v js datum objekt datum in čas)
        - timeMS (microsoft datum pretvori v js datum objekt čas)
        - file (datoteka za prenos) - vsebina je pot do datoteke
        - image (buffer slike)
        - multilineInput (Večvrstično textovno polje)
    */
    FieldType?: string;
    /** Vrednost po kateri dropdown komponenta pridobi vrednosti */
    LookupSource?: string;
    /** Parameter, ki se pošlje poleg v pridobivanje podatkov za dropdown */
    LookupParam?: any;
    /**Tip filtra: default, numeric */
    FilterType?: string;
    /** Vrstni red v katerem bodo stolpci prikazani */
    SortID?: number;
    /** Ali se stolpec prikazuje uporabniku */
    FieldVisible?: boolean;
    /**Default Obvezen vnos, ker je takšna narava podatka*/
    FieldRequired?: boolean;
    /** Poveče je vrednosti v tem stolpcu možno urejati, privzeto false */
    FieldEditable?: boolean;
    /** Širina celice, privzeto 70px */
    FieldWidth?: number;
    /** Višina celice */
    FieldHeight?: number;
    /** Labla polja, ki predstavlja ime ali KEy prevoda za polje. */
    FieldLabel?: string;
    /** Privzeta vrednost polja */
    FieldDefault?: string;
    /** Dodaten opis polja, ki se bo prikazal kot tooltip*/
    FieldTooltip?: string;
    /** Uporabljeno za filtriranje prikazanih polj*/
    FieldSelected?: boolean;
    /**Prikaz sume/seštevka vseh vrstic izbranega stolpca */
    FieldSum?: boolean;
    /**Pove če je stolpec prikazan po privzetem */
    NotVisibleByDefault?: boolean;
    /**Obvezen vnos s strani uporabnika*/
    FieldRequired2?: boolean;
    /**Ali je polje vidno na mobitelu*/
    FieldNotVisibleOnMobile?: boolean;
    /** Vrednosti za dropdown - fiksno podane */
    data?: any[];
    tabIndex?: number;

}


