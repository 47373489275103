import { ComponentViewDTO } from "../../Core/Common/ComponentViewDTO";

export const FirmDevicesGridSettings: ComponentViewDTO = {
    ComponentType: 1,
    ComponentName: "FirmDevicesGridView",
    Tabs: [
        {
            TabName: '',
            Groups: [
                {
                    GroupName: '',
                    CSSStyle: '',
                    Fields: [
                        { FieldName: "deviceName", FieldType: 'input' },
                        { FieldName: "deviceDescription", FieldType: 'input' },
                        { FieldName: "deviceId", FieldType: 'input' },
                        { FieldName: "createdAt", FieldType: 'dateTime'},
                        { FieldName: "registeredBy", FieldType: 'input' },
                        { FieldName: "busUnitName", FieldType: 'input' }
                    ]
                }
            ]
        }
    ]
    ,
    Add: false,
    Edit: false,
    Delete: true,
    EnableExport: true,
    isSelectable: true,
    pageable: true,
    //showCheckboxColumn: true,
}