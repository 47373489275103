import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ComponentViewDTO } from 'app/Core/Common/ComponentViewDTO';
import { FormComponentView } from 'app/Core/Components/form-with-labels-v2/FormComponentView';
import { AppConfig } from 'app/Core/CoreServices/configuration-services/configuration.service';
import { LoadingService } from 'app/Core/CoreServices/loading-services/loading.service';
import { MessageType } from 'app/Core/CoreServices/message-services/message';
import { MessageService } from 'app/Core/CoreServices/message-services/message.service';
import { DataEditService } from 'app/services/dataEditService/data-edit.service';
import { LoginService } from 'app/services/loginService/login.service';
import { dtoUsers } from '../../common/dtos/dtoUsers';
import { UserFormSettings } from './userFormSettings';
import { UserComponentView } from './userGridSettings';
import { LookupItem } from 'app/Core/Components/grid-v2/LookupItem';
import { VerifyEmail, CheckEmail, ValidateEmail } from 'app/services/helperFunctions/functions';
import { translate } from '@angular/localize/src/translate';
import { dtoBusUnit } from 'app/common/dtos/dtoBusUnit';
import { flattenDiagnosticMessageText } from 'typescript';
import { NavigationService } from 'app/services/navigation-services/navigation.service';
import { HotObservable } from 'rxjs/internal/testing/HotObservable';
import { isMobileMenu } from 'app/Core/CoreFunctions/HelperFunctions';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
	public currUser: dtoUsers = undefined;

	public allUsers: dtoUsers[] = [];
	public usersGridSettings: ComponentViewDTO = UserComponentView;

	public newUser: boolean = false;
	public userDialogVisible: boolean = false;
	public addUserDialogVisible: boolean = false;
	public dataActive: LookupItem[] = [];
	public selectedData: any = -1;
	public btnAdd: boolean = false;

	public curUsername: string;
	public curPassword: string;
	public busUnits: LookupItem[] = [];
	public selectedBusUnit: number;
	public curIsActive: boolean = false;

	public isSmall: boolean = false; 

	constructor(private service: DataEditService, private loadingService: LoadingService, private messageService: MessageService,
		private translate: TranslateService, private loginService: LoginService, private navigationService: NavigationService) { }

	async ngOnInit() {
		if (Date.parse(this.loginService.user.tokenValidTo.toString()) < new Date(Date.now()).getTime()) {
			this.loginService.doLoginFromSessionStorage();
		}

		if (!this.loginService.isAdmin())
			this.navigationService.navigateToUrlWQueryParams('postponed');
		else {
			this.getLookupData();
			await this.getData();
			this.getBusUnits();

			if (localStorage.getItem('licensed') == null) {
				this.btnAdd = true;
			}

			this.usersGridSettings.height = (window.innerHeight - AppConfig.settings.headerHeight) * 0.7;
		}
	}

	public async getLookupData() {
		this.dataActive = [
			{ label: await this.translate.get("all").toPromise(), value: -1 },
			{ label: await this.translate.get("active").toPromise(), value: true },
			{ label: await this.translate.get("inactive").toPromise(), value: false },
		];
	}

	/**Filtrira tabelo glede na polje 'active'*/
	public async filterData() {

		await this.getData().then(() => {
			if (this.selectedData != -1) {
				this.allUsers = this.allUsers.filter(el => el.active == this.selectedData);
			}
		}).catch((err) => {
			this.messageService.showMessage({ content: "errorFilterUsers", type: MessageType.error });
		});
	}

	public async getData() {
		try {
			this.loadingService.busyYes();

			if (Date.parse(this.loginService.user.tokenValidTo.toString()) < new Date(Date.now()).getTime()) {
				await this.loginService.doLoginFromSessionStorage();
			}

			await this.service.getAllUsers().toPromise()
				.then((val) => {
					this.allUsers = val;
					this.allUsers.forEach(el => {
						if (el.active) {
							el['activeText'] = "✔️";
						} else {
							el['activeText'] = "❌";
						}

						if (el.isAdmin) {
							el['isAdminText'] = "✔️";
						} else {
							el['isAdminText'] = "❌";
						}
					})
				})
				.finally(() => {
					this.loadingService.busyNo();
				});
		} catch (err) {
			this.loadingService.busyNo();
		}
	}

	public getBusUnits() {
		this.service.getAllBusUnits(true).toPromise()
		.then((res) => {
			res.forEach((el: dtoBusUnit) => {
				this.busUnits.push({ label: el.busUnitName, value: el.busUnitId });
			});
		}) 
		.catch((er) => {

		});
	}

	async operationHandler({ operation, dataItem, selection }) {
		switch (operation) {
			case 'selectionChanged': {
				this.currUser = dataItem;
				break;
			}
			case 'remove': {
				if (dataItem['userId'] != this.loginService.user.userId) {
					if (!dataItem['active']) {

						this.loadingService.busyYes();
						await this.service.deleteUser(dataItem['userId']).toPromise()
							.then(() => {
								this.messageService.showMessage({ content: 'userRemoved', type: MessageType.success });
								this.getData();
							})
							.catch((err) => {
								if (err['error'] != null && typeof err['error'] == "string" && err['error'].includes("\"FK_User\" on table \"FirmSignDevice\"")) {
									this.messageService.showMessage({ content: 'userHasConnections', type: MessageType.error });
								} 
								else if (err['status'] == 500){
									this.messageService.showMessage({ content: 'userHasDevices', type: MessageType.error });
								}
								else {
									if (err['error'] != null && typeof err['error'] == "string")
										this.messageService.showMessage({ content: err["error"], type: MessageType.error });
									else
										this.messageService.showMessage({ content: 'unknownErr', type: MessageType.error });
								}
							}).finally(() => {
								this.loadingService.busyNo();
							});
					} else {
						this.messageService.showMessage({ content: 'errActiveUser', type: MessageType.error });
					}
				} else {
					this.messageService.showMessage({ content: 'errDelUser', type: MessageType.error });
				}

				break;
			}
			case 'dblClick': {
				this.currUser = dataItem;

				this.showDialog();
				break;
			}
		}
	}

	/**
	 * Prikaz dialoga za dodajanje in posodablanje uporabnikov
	 * @param add če je TRUE se doda nov uporabnik
	 */
	public async showDialog(add?: boolean) {
		if (add) {
			this.currUser = new dtoUsers();
			this.curUsername = "";
			this.curPassword = "";
			this.curIsActive = false;

			this.newUser = true;

			this.userDialogVisible = true;
		} else {
			this.newUser = false;

			await this.service.getUserById(this.currUser.userId).toPromise()
				.then((val) => {
					this.currUser = val;

					this.curUsername = this.currUser.username;
					this.curPassword = this.currUser.password;
					this.selectedBusUnit = this.currUser.busUnitId;
					this.curIsActive = this.currUser.active;
					
					this.userDialogVisible = true;
				})
				.catch((err) => {
					this.messageService.showMessage({ content: 'errCanGetUser', type: MessageType.error });
				});
		}
	}

	public closeDialog() {
		this.userDialogVisible = false;
	}

	public refresh() {
		this.selectedData = -1;
		this.getData();
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event) {
		event.target.innerWidth;
		
		this.usersGridSettings.height = (window.innerHeight - AppConfig.settings.headerHeight) * 0.7;
		this.isSmall = isMobileMenu();
	}

	public async saveUserData() {
		this.currUser.username = this.curUsername;
		this.currUser.password = this.curPassword;
		this.currUser.active = this.curIsActive;
		this.currUser.busUnitId = this.selectedBusUnit;

		console.log(this.currUser);

		if (this.newUser) {
			//DODAJANJE NOVEGA UPORABNIKA
			this.currUser.firmId = this.loginService.user.firmId;

			// if (CheckEmail(this.currUser.username) && ValidateEmail(this.currUser.username)) {
			// } else {
			// 	this.messageService.showMessage({ content: 'usernameError', type: MessageType.error });
			// }
			if (this.currUser.username != "" && this.currUser.password != "") {
				await this.service.createNewUser(this.currUser).toPromise()
					.then(async () => {
						this.messageService.showMessage({ content: 'saveSucessfull', type: MessageType.success });
						await this.getData();
						this.closeDialog();
						this.newUser = false;
					})
					.catch((err) => {
						this.messageService.showMessage({ content: err['error'], type: MessageType.error });
					});
			} else {
				this.messageService.showMessage({ content: 'userDataMissing ', type: MessageType.error });
			} 
		} else {
			//POSODABLANJE OBSTOJEČEGA UPORABNIKA
			var activation = this.curIsActive;

			await this.service.updateUser(this.currUser).toPromise()
				.then(() => {
					if (activation) {
						this.messageService.showMessage({ content: 'activationSuccess', type: MessageType.info });
					} else {
						this.messageService.showMessage({ content: 'deactivationSuccess', type: MessageType.info });
					}
					this.messageService.showMessage({ content: 'saveSucessfull', type: MessageType.success });
				})
				.catch((err) => {
					this.messageService.showMessage({ content: err['message'], type: MessageType.success });
				})
				.finally(async () => {
					await this.getData();
					this.closeDialog();
				});
		}
	}
}
