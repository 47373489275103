import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

  constructor() { }

  // Funkcije za lokalno shrambo pridobljenih datotek v sejo
  async setSessionStorageData(dataKey: string, documents: any): Promise<void> {
    sessionStorage.setItem(dataKey, JSON.stringify(documents));
  }
  getSessionStorageData(dataKey: string): any {
    const data = JSON.parse(sessionStorage.getItem(dataKey));
    return data;
  }
  async clearSessionStorageData(key: string) {
    sessionStorage.removeItem(key);
  }

  // Funkcije za lokalno shrambo dototek
  async setLocalStorageData(dataKey: string, documents: any): Promise<void> {
    localStorage.setItem(dataKey, JSON.stringify(documents));
  }
  getLocalStorageData(dataKey: string): any {
    if (localStorage.getItem(dataKey) != undefined && localStorage.getItem(dataKey) != "undefined") {
      const data = JSON.parse(localStorage.getItem(dataKey));
      return data;
    }
    return undefined;
  }

  async clearLocalStorageData(key: string) {
    localStorage.removeItem(key);
  }


}
