export class dtoUsers {
    constructor() {
        this.username = this.firmId = this.userId = this.token = this.createdAt = this.active = this.busUnitId = this.isAdmin = undefined;
    }

    username: string;
    password: string;
    firmId: number;
    userId: number;
    token: string;
    createdAt: any;
    active: boolean;
    busUnitId: number;
    isAdmin: boolean;
}
