export class Message {
    type: MessageType;
    content: string;

    constructor(content: string, type: MessageType) {
        this.content = content;
        this.type = type;
    }
}

export enum MessageType {
    error = 'error',
    warn = 'warn',
    success = 'success',
    info = 'info',
    default = 'default'
}
