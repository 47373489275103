import {
    Component, OnInit, OnChanges, Input, ViewChild, Output,
    EventEmitter, ViewEncapsulation, HostListener, SimpleChanges, HostBinding, ElementRef, AfterViewInit, AfterContentInit, NgModule
} from '@angular/core';
import { State, process, CompositeFilterDescriptor, FilterDescriptor, GroupDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, SelectableSettings, SelectionEvent, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { FormGroup, Validators, AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { AddEvent, EditEvent, GridComponent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridColumnClass } from './gridColumnClass';


import { isCompositeFilterDescriptor, aggregateBy } from '@progress/kendo-data-query';
import { FilterService } from '@progress/kendo-angular-grid';

import { GridComponentView, copyTabGroupFieldsToFields } from './GridComponentView';
//import * as $ from "jquery";
import { DomSanitizer } from '@angular/platform-browser';
import { ValidationError, BaseClass } from '../../Common/BaseClass';
import { b64toBlob } from '../../CoreFunctions/Base64ToBlob';
import { LocalStorageService } from '../../CoreServices/localStorage-services/local-storage.service';
import { LookupItem, LookupCriteria } from './LookupItem';
import { SettingService } from '../../CoreServices/setting-services/setting.service';
import { ComponentFieldDTO } from '../../Common/ComponentViewDTO';
import { DataEditService } from 'app/services/dataEditService/data-edit.service';
import { dtoDocument } from 'app/common/dtos/dtoDocument';
import { MessageService } from 'app/Core/CoreServices/message-services/message.service';
import { MessageType } from 'app/Core/CoreServices/message-services/message';

import { newGuid } from 'app/Core/CoreFunctions/Guid';
import { UserFormSettings } from 'app/appComponents/users/userFormSettings';

var arraySort = require('array-sort');

@Component({
    selector: 'app-grid-v2',
    templateUrl: './grid-v2.component.html',
    styleUrls: ['./grid-v2.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class GridV2Component implements OnInit, OnChanges {
    /**doda stil na host-a, torej v <app-grid-v2> nastavi širino tako da se ob resizu pojavi horizontalni scrollbar */
    @HostBinding('style.width') public width: string = '100%';

    //#region VARIABLES

    // Stanje tabele (sortiranje, stran, ...)
    public gridState: State = {
        skip: 0,
        take: 50,
    };

    // parametri za grupiranje
    @Input() groupable: boolean;
    @Input() group: GroupDescriptor[] = [];

    @Input() height: number; //Višina grida podana iz parent komponente
    @Input() maxHeight: number; //največja višina grida - ko se doseže se pojavi scrollbar (kar si želimo v dialogih)
    @Input() data: any[]; // Podatki
    @Input() gridSettings: GridComponentView;  // Nastavitve tabele
    public validationErrors: ValidationError[]; //validacijske napake
    @Input('ValidationErrors') set setValidationErrors(value: ValidationError[]) {
        this.validationErrors = value;
    }
    /**Single, multiple (default)*/
    @Input() selectionMode: string = undefined;

    public baseClass: BaseClass = undefined;

    /**Spremenljivka ki pove število trenutno prikazanih vrstic v gridu - za prikaz v nogi grida */
    public shownRowsCount: Number = this.gridState.take;
    /**Število vseh elementov grida */
    public allRowsCount: number;
    /**Polje trenutno nastavljenih filtrov */
    public appliedFilters: Array<any> = new Array<any>();
    /**Vrednost checkboxa za izbiro vseh vrstic hkrati */
    public allSelected: boolean = false;

    // Form skupina v kateri bodo podatki vrstice, ki jo je uporabnik izbral
    private formGroup: FormGroup;
    private editedRowIndex: number;
    private isNew = false;
    public selectableSettings: SelectableSettings = {
        enabled: true
    };
    private lookupItems: Map<string, LookupItem[]> = new Map();

    /**Vidnost dialoga za prikazovanje stolpcev tabele */
    public gridFieldsDialogVisible: boolean = false;

    @ViewChild(GridComponent, { static: true }) private grid: GridComponent;
    public gridView: GridDataResult;

    @Output() operationEvent: EventEmitter<{ operation: string, dataItem?: any, selection?: any, changedProperty?: string, formGroup?: any }> = new EventEmitter();
    //public tabIndexVal: any;
    //, tabIndex?: any

    /**Dogodek ki se sproži ob kliku na prenos priponke - vrnejo se podatki priponke - za npr. vodenje revizije */
    @Output() fileDownloadEvent: EventEmitter<{ dataItem?: any }> = new EventEmitter();

    /**Input spremenljivka ki pove glede na kateri stolpec bomo pridobivali izbrane vrstice (multiple select) */
    @Input() kendoGridSelect?: string;

    public mySelection: number[] = [];
    //public selectionChangedEventHandlerExecuted: boolean = false;

    /**za sume vrstic */
    public aggregates: any[] = [];//[{field: 'UnitPrice', aggregate: 'sum'}];
    /**za sume vrstic */
    public total: any = undefined;

    /**polje ki pove za posamezne stolpce s checkboxi če je checkbox za selectAll checked */
    public allSelectedSave: boolean[] = [];

    public clickedDataItem: any;

    public confirmOpened: boolean = false;
    public tmpDataItem: any;

    //#region oldKeyEvent
    // // Zaznam pritisk na tipkovnici
    // @HostListener('window:keydown', ['$event'])
    // onKeyPress(event) {
    //     switch (event.key) {
    //         case 'Enter':
    //             if (!event.shiftKey) {
    //                 if (this.selectionChangedEventHandlerExecuted != true) {
    //                     this.saveHandler();
    //                 }
    //                 this.selectionChangedEventHandlerExecuted = false;
    //             }

    //             break;
    //         case 'Escape':
    //             this.closeEditor(this.grid);
    //             break;
    //     }
    // }
    //#endregion

    public autoResizeColumns() {
        this.grid.autoFitColumns();

        //this.grid.columns.forEach((column) => {
        //    debugger;
        //    const tmp: ComponentFieldDTO = this.gridSettings.Fields.find(item => item.FieldName === column.);
        //    tmp.FieldWidth = column.width;

        //    debugger;
        //    this.refreshGridSettings(this.gridSettings);
        //});


    }



    public onKeydown(sender: any, event: any) {
        switch (event.key) {
            case 'Enter':
                if (!event.shiftKey) {
                    this.editHandler({
                        dataItem: undefined,
                        rowIndex: this.editedRowIndex + 1,
                        sender: this.grid,
                        isNew: false
                    });
                    //this.saveHandler('Enter');
                }
                break;
            case 'Escape':
                this.closeEditor(this.grid);
                this.operationEvent.emit({ operation: 'cancel' });
                this.mySelection = [];

                break;
            case 'Delete':
                if (this.mySelection.length == 1 && !this.isInEditingMode) {
                    this.removeHandler(this.gridView.data[this.mySelection[0]], true);
                }
                break;
        }
    }

    /**Proženje ob dvojnem kliku na vrstico grida */
    public onDblClick() {
        this.operationEvent.emit({ operation: 'dblClick', dataItem: this.clickedDataItem });
        //tabIndex: this.tabIndexVal
    }

    //#endregion VARIABLES

    constructor(private messageService: MessageService, private localStorageService: LocalStorageService, private settingService: SettingService, private sanitizer: DomSanitizer, private service: DataEditService) {
    }

    async ngOnInit() {
        this.allData = this.allData.bind(this);
        this.shownRowsCount = this.gridView.data.length;
        //this.rowCallback(this.gridView.data);
        this.getSum();

        //this.operationEvent.emit({ operation: 'filter', dataItem: this.gridView.data });

        if (this.groupable) {
            await this.groupChange(this.group);
        }
    }

    // Zazna spremembe v input podatkih
    ngOnChanges(changes: SimpleChanges) {
        if (changes['data'] && this.gridSettings) {
            if (!this.data) {
                return;
            }
            this.closeEditor(this.grid);
            this.gridView = process(this.data, this.gridState);
            this.mySelection = [];
            //this.operationEvent.emit({ operation: 'filter', dataItem: this.gridView.data });
        }
        if (changes['gridSettings']) {
            if (!this.data) {
                return;
            }
            this.gridSettings = copyTabGroupFieldsToFields(this.gridSettings);
            //this.gridSettings.copyTabGroupFieldsToFields();

            this.closeEditor(this.grid);
            this.getGridState();
            this.getLookupItems();
            //this.getGridColumns();
            this.adjustGridColumns();

            if (this.gridSettings.pageable == false) {
                this.gridState.skip = undefined;
                this.gridState.take = undefined;
            }

            this.gridView = process(this.data, this.gridState);
        }

        this.gridState.skip = 0; //vrnemo na prvo stran če so bile spremembe - npr po iskanju vrstic
        this.gridView = process(this.data, this.gridState);

        this.shownRowsCount = this.gridView.data.length;

        //pošljemo vse sortirane in filtrirane podatke
        this.emitAllOrderedData(this.gridState);

        this.getSum();
        this.autoResizeColumns();
        setTimeout(() => {

            this.autoResizeColumns();
        }, 1);
    }

    //#region fieldSelectionDialog
    public openGridFieldsDialog() {
        this.gridFieldsDialogVisible = true;
    }

    closeEventDialog() {
        this.gridFieldsDialogVisible = false;
    }

    public async groupChange(groups: GroupDescriptor[]): Promise<void> {
        this.gridView = process(this.data, this.gridState);
        this.group = groups;
    }

    public refreshGridSettings(gridSettings: GridComponentView) {
        this.gridFieldsDialogVisible = false;
        this.gridSettings = undefined;

        if (gridSettings.Fields != undefined) {
            gridSettings.Fields = arraySort(gridSettings.Fields, ['SortID'], { reverse: false });
        }
        this.gridSettings = gridSettings;

        //Shranjevanje v bazo
        let columns: GridColumnClass[] = [];
        for (let gField of this.gridSettings.Fields.filter(x => x.FieldVisible == true)) {
            columns.push({
                GridEnum: gridSettings.ComponentName,
                ColumnName: gField.FieldName,
                IsShown: gField.FieldVisible,
                SortID: gField.SortID,
                Width: gField.FieldWidth
            });
        }
        this.settingService.saveUserGridColumnSettings(this.gridSettings.ComponentName + "_columns", columns).subscribe(res => {
            this.adjustGridColumns();
        });
    }
    //#endregion

    // Pridobim stanje tabele
    private async getGridState() {
        //const state = this.localStorageService.getSessionStorageData(this.gridSettings.ComponentName);
        const state = this.localStorageService.getLocalStorageData(this.gridSettings.ComponentName);
        if (state) {
            delete state.group;

            this.gridState = state;
            //pridobimo nastavljene filtre v iz localStorage, jih shranimo v posebej polje do katerega lahko dostopamo
            if (this.gridState.filter != undefined) {
                this.appliedFilters = this.gridState.filter.filters.map(f => isCompositeFilterDescriptor(f) ? f.filters : [f]).reduce((p, n) => p.concat(n), []);
            }
        }
    }

    private async getGridColumns() {
        // const tmp = await this.settingService.getUserGridColumnSettings(this.gridSettings.gridEnum).toPromise();
        // if (tmp) {
        //   this.gridSettings.columns = tmp;
        // }

        // const tmp = await this.localStorageService.getLocalStorageData(this.gridSettings.state);
        // if (tmp) {
        //   this.gridSettings.columns = tmp;
        // }
    }

    /**
     * Funkcija pridobi iz baze, katera polja tabele so prikazana
     */
    private async adjustGridColumns() {
        if (this.gridSettings != undefined) {
            let userGridColumnSettings: GridColumnClass[] = await this.settingService.getUserGridColumnSettings(this.gridSettings.ComponentName + "_columns").toPromise();
            let shownFieldsDB = userGridColumnSettings.filter(x => x.IsShown);

            if (shownFieldsDB != undefined && userGridColumnSettings.length > 0) {
                for (let gField of this.gridSettings.Fields) { //Zanka čez vse stolpce

                    var tmpAllShownFieldDB = shownFieldsDB.filter(x => x.ColumnName == gField.FieldName);//Pridobimo stolpec, ki je shranjen v bazi
                    if (tmpAllShownFieldDB.length > 0) //Če obstaja v bazi - uporabnik si je prilagodil vidnost ali vrstni red
                    {
                        var currShownFieldDB = tmpAllShownFieldDB[0];
                        gField.FieldVisible = true;
                        gField.SortID = currShownFieldDB.SortID;
                        gField.FieldWidth = currShownFieldDB.Width;
                    }
                    else {
                        gField.FieldVisible = false;
                    }
                }
                this.gridSettings.Fields = arraySort(this.gridSettings.Fields, ['SortID'], { reverse: false });
                //this.gridSettings.columns = this.gridSettings.columns.splice(0); // Osvežimo pogled
            }
            else { //če še ni gridSettingsov za ta grid
                for (let gField of this.gridSettings.Fields) { //Zanka čez vse stolpce
                    if (gField.NotVisibleByDefault == true)
                        gField.FieldVisible = false;
                    else gField.FieldVisible = true; //privzeto so prikazani vsi stolpci
                }

                this.autoResizeColumns();
            }
        }
    }

    // Pridbim lookuIteme iz strežnika
    private getLookupItems() {
        this.gridSettings.Fields.forEach(async item => {
            if (item.data != undefined && this.lookupItems.get(item.LookupSource) === undefined) {
                this.lookupItems.set(item.LookupSource, item.data);
            }
            else if (item.LookupSource !== undefined && this.lookupItems.get(item.LookupSource) === undefined) {
                //const items = await this.commonService.getLookupItems(new LookupCriteria(item.LookupSource)).toPromise();
                //this.lookupItems.set(item.LookupSource, items);
            }
        });
    }

    // PRidobim vrednost lookupItem-a
    public getLookupValue(source: string, value: string) {
        const items = this.lookupItems.get(source);
        if (items) {
            const tmp = items.find(item => item.value === value);
            return tmp ? tmp.label : value;
        }
    }

    //#region HANDLERS

    // Handler funkcija za dodajanje nove vrstice v tabelo
    public addHandler({ sender }: AddEvent) {
        this.mySelection = [];
        this.closeEditor(this.grid);

        this.createFormGroup();
        this.isNew = true;
        sender.addRow(this.formGroup);  // Dodam novo vrstico v tabelo
        this.operationEvent.emit({ operation: 'edit', dataItem: this.formGroup });
        this.getSum();
    }

    // Odpre novo vrstico za urejanje
    public editHandler({ sender, rowIndex, dataItem }: EditEvent): void {
        // Preverim če form skupina obstaja in je validna
        if (this.formGroup && !this.formGroup.valid) {
            return;
        }

        this.saveHandler(); // Shranim vrstico, ki se je urejala pred to
        if (dataItem == undefined) {
            dataItem = this.data[rowIndex];
        }
        this.createFormGroup(dataItem); // Ustvarim novo form skupino
        this.editedRowIndex = rowIndex;
        this.mySelection = [rowIndex];
        sender.editRow(rowIndex, this.formGroup); // Vrstici omogočim urejanje

        // Pošljem dogodek da bo starš vedel, da ni nov podatek
        this.operationEvent.emit({ operation: 'edit', dataItem: dataItem });
        if (this.data.length != 0) {
            this.allRowsCount = this.data.length; //v primeru da dodamo novo vrstico, se posodobi števec vseh vrstic (+1)
        }

        //fokus prvega stolpca, ki se lahko editira
        this.focusFirstElement();
    }

    /**Spremenjena vrednost field-a - V parent komponento se pošlje spremenjena vrednost ter celoten formGroup, za potrebe spreminjanja ostalih podatkov - na primer kalkulacije*/
    public updateValueHandler(formControl: string, value: string): void {
        this.formGroup.get(formControl).patchValue(value);
        this.formGroup.markAsDirty();
        this.operationEvent.emit({ operation: 'propertyChange', dataItem: value, changedProperty: formControl, formGroup: this.formGroup });
    }

    private focusFirstElement() {
        setTimeout(() => {
            for (let col of this.gridSettings.Fields) {
                if (col.FieldEditable == true) {
                    let element: HTMLElement = document.getElementById("input_" + col.FieldName);
                    if (element != undefined) {
                        element.focus();

                    }
                    return;
                }
            }
        });
    }


    /**Custom handler funkcija za brisanje vrstice iz tabele */
    public removeHandler(dataItem, value: boolean, doDelete?: boolean) {
        if (!this.gridSettings.DeleteWOConfirmation) {
            if (dataItem != undefined) {
                this.tmpDataItem = dataItem;
            }
            if (doDelete) {
                this.operationEvent.emit({ operation: 'remove', dataItem: this.tmpDataItem });  // Pošljem dogodek za brisanje podatka
                this.getSum();
            }
            if (value) {
                this.confirmOpened = true;
            } else {
                this.confirmOpened = false;
            }
        } else {
            this.operationEvent.emit({ operation: 'remove', dataItem: dataItem });  // Pošljem dogodek za brisanje podatka
            this.getSum();
        }
    }
    // // Handler funkcija za brisanje vrstice iz tabele
    // public removeHandler({ dataItem }) {
    //     this.operationEvent.emit({ operation: 'remove', dataItem: dataItem });  // Pošljem dogodek za brisanje podatka
    // }

    // Handler funkcija za preklicanje urejanja vrstice
    public cancelHandler(event) {
        this.closeEditor(this.grid);
        this.operationEvent.emit({ operation: 'cancel' });
    }

    // Pošljem dogodek s podatki
    public saveHandler(actionEnum?: string): void {
        // Preverim če je vklopljeno urejanje in če je vrstica validna
        if (!(this.isInEditingMode && this.formGroup.valid)) {
            return;
        }
        //
        // Preverim če je v vrstici bilo kaj spremenjeno
        if (this.formGroup.dirty) {
            // Pošljem dogodek za ustvarjanje ali posodabljanje podatka
            this.operationEvent.emit({ operation: 'save', dataItem: this.formGroup.value });
        }

        this.closeEditor(this.grid);
        this.getSum();

    }

    // Handler za spreminjanje velikosti stolpca
    public resizeHandler(event) {
        const tmp: ComponentFieldDTO = this.gridSettings.Fields.find(item => item.FieldName === event[0].column.field);
        tmp.FieldWidth = event[0].newWidth;

        this.refreshGridSettings(this.gridSettings);
        //this.localStorageService.setLocalStorageData(this.gridSettings.state, this.gridSettings.columns);
    }

    // Handler za spreminjanje vrstnega reda stolpcev
    public sortHandler(event) {
        event.preventDefault(); //!!!Zelo pomembna vrstica!!! - brez tega se stolpci sortirajo samodejno, spreminja se le pogled tabele in ne dejanski vrstni red v zapisu!

        //če prikazujemo stolpec s checkboxi moramo spremeniti vrednost indexov v eventu saj je stolpec pred ostalimi stolpci definirane tabele
        if (this.gridSettings.showCheckboxColumn) {
            event.oldIndex--;
            event.newIndex--;
        }

        let tmpGridColumns: ComponentFieldDTO[] = [...this.gridSettings.Fields];
        // Naredim kopijo objekta
        const tmp = Object.assign({}, tmpGridColumns[event.oldIndex]);
        // Izbrišem objekt iz niza
        tmpGridColumns.splice(event.oldIndex, 1);
        // Objekt dodam nazaj v niz na novem indexu
        tmpGridColumns.splice(event.newIndex, 0, tmp);
        for (var i = 0; i < tmpGridColumns.length; i++) //nastavimo sortID
        {
            tmpGridColumns[i].SortID = i + 1;
        }
        this.gridSettings.Fields = tmpGridColumns;
        this.refreshGridSettings(this.gridSettings);
    }

    //#endregion HANDLERS

    //#region EDITOR-METHODS

    // Handler funkcija ob kliku celice v tabeli, vrstico kliknjene celice spremeni v takšno, ki jo je možno urejati
    public editClick({ dataItem, rowIndex, isEdited }: any): void {
        //Prožimo event selection changed
        //this.operationEvent.emit({ operation: 'selectionChanged', dataItem: dataItem, selection: this.mySelection });

        //Če je sprememba, prožimo še edithandler
        //prej namesto delete bilo command
        if (!isEdited) { // drugi del if stavka dodan v TronPortal - brez tega izginja gumb za brisanje vrstice saj ga funkcija editHandler skrije
            this.editHandler({
                dataItem: dataItem,
                rowIndex: rowIndex,
                sender: this.grid,
                isNew: false
            });
        }

        this.clickedDataItem = dataItem;
    }

    public selectionChangeEvent(selectionParam: SelectionEvent) {
        if (selectionParam.selectedRows.length > 0) {
            //this.selectionChangedEventHandlerExecuted = true;

            let tmpDataItem = selectionParam.selectedRows[0].dataItem;
            let tmpIndex = selectionParam.selectedRows[0].index;

            //prej namesto delete bil command
            this.editHandler({
                dataItem: tmpDataItem,
                rowIndex: tmpIndex,
                sender: this.grid,
                isNew: false
            });

            //Prožimo event selection changed
            this.operationEvent.emit({ operation: 'selectionChanged', dataItem: tmpDataItem, selection: this.mySelection });

        }
    }

    public selectedKeysChangedEvent() {
        this.allSelected = false;
        this.operationEvent.emit({ operation: 'selectedKeysChanged', dataItem: undefined, selection: this.mySelection });
    }

    /**funkcija vrne true če je vrstica z indexom izbrana, false če ni izbrana */
    public getSelection(index: Number): boolean {
        return this.mySelection.filter(x => x == index).length > 0;
    }

    // Vrne stanje urejanja vrstice
    public get isInEditingMode(): boolean {
        if (this.gridSettings.Fields.filter(x => x.FieldEditable == true).length > 0)//Če je katero polje mogoče urejati, potem postavim isInEditingMode
            return this.editedRowIndex !== undefined || this.isNew;
        else return false;
    }

    // Zaključim urejanje vrstice
    private closeEditor(grid: GridComponent, rowIndex: number = this.editedRowIndex): void {
        this.isNew = false;
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
    }

    //#endregion EDITOR-METHODS

    // Ustvarim novo form skupino, v kateri bodo podatki trenutne vrstice, ki se ureja
    private createFormGroup(dataItem?: any): void {
        //#region oldCode
        // if (!dataItem) {
        //     // Ustvarim nov prazen objekt
        //     const tmp: any = {};
        //     this.gridSettings.Fields.forEach(item => {
        //         // V nov objekt dodam samo polja katera lahko urejamo
        //         if (item['type']) {
        //             switch (item['type']) {
        //                 case 'input':
        //                     tmp[item.FieldName] = '';
        //                     break;
        //                 case 'textareaInput':
        //                     tmp[item.FieldName] = '';
        //                     break;
        //                 case 'checkbox':
        //                     tmp[item.FieldName] = false;
        //                     break;
        //                 case 'dropdown':
        //                     tmp[item.FieldName] = undefined;
        //                     break;
        //             }
        //             // tmp[item.field] = undefined;
        //         }
        //     });

        //     dataItem = tmp;
        // }

        // const group = new FormGroup({});
        // Object.keys(dataItem).forEach(key => {
        //     group.addControl(key, new FormControl(dataItem[key], null));
        // });
        //#endregion

        if (dataItem == undefined)
            dataItem = {}; //Prazna inicializacija
        const group = new FormGroup({});

        for (let field of this.gridSettings.Fields) {
            let key: string = field.FieldName;

            // let validators: any[] = [];
            // if(field.FieldRequired)
            //     validators.push(Validators.required);

            switch (field.FieldType) {
                case 'time':
                case 'date': {
                    if (dataItem[key] != undefined)
                        group.addControl(key, new FormControl(new Date(dataItem[key]), null));
                    else group.addControl(key, new FormControl(null, null));
                    break;
                }
                default: {
                    //Glede na tip, nastavim pravilno začetno vrednost, če ta ni podana
                    if (dataItem[key] != undefined)
                        group.addControl(key, new FormControl(dataItem[key], null));
                    else {
                        switch (field.FieldType) {
                            case 'input':
                            case 'maskedInput':
                                group.addControl(key, new FormControl('', null)); break;
                            case 'numericInput':
                            case 'numericIntegerInput':
                                group.addControl(key, new FormControl(undefined, null)); break;
                            case 'dropdown':
                                group.addControl(key, new FormControl(null, null)); break;
                        }
                    }
                }
            }
        }
        this.formGroup = group;
    }

    // Spremenim vrednosti spremenljivke state ob spremembi
    public async onStateChange(state: State): Promise<void> {
        this.gridState = state;
        this.gridView = process(this.data, state);

        //pošljemo vse sortirane in filtrirane podatke
        this.emitAllOrderedData(state);

        //posodobimo tekst z številom prikazanih vrstic
        this.shownRowsCount = this.gridView.data.length;

        //pridobimo podatke nastavljenih filtrov - izbran filter in vrednost po kateri filtriramo
        if (this.gridState.filter != undefined) {
            this.appliedFilters = this.gridState.filter.filters.map(f => isCompositeFilterDescriptor(f) ? f.filters : [f]).reduce((p, n) => p.concat(n), []);
            this.getFilterOperatorSigns();
        }

        this.getSum();
        this.mySelection = [];
    
        this.localStorageService.setLocalStorageData(this.gridSettings.ComponentName, this.gridState);
    }

    /**Funkcija iz privzeto nastavljenih oznak za operatorje (kot so npr. 'eq', 'neq', 'lte') spremeni te v simbole ('=', '!=', '<') za prikaz v vrstici s podatki filtrov */
    getFilterOperatorSigns() {
        for (let filter of this.appliedFilters) {
            filter.valueDisplay = filter.value;

            if (filter.operator == "eq" || filter.operator == "contains" || filter.operator == "startswith" || filter.operator == "endswith") {
                filter.operatorSign = "=";

                if (filter.operator == "contains") {
                    filter.valueDisplay = "'%" + filter.value + "%'";
                }
                if (filter.operator == "startswith") {
                    filter.valueDisplay = "'" + filter.value + "%'";
                }
                else if (filter.operator == "endswith") {
                    filter.valueDisplay = "'%" + filter.value + "'";
                }
            }
            else if (filter.operator == "neq" || filter.operator == "doesnotcontain") {
                filter.operatorSign = "!=";

                if (filter.operator == "doesnotcontain") {
                    filter.valueDisplay = "'%" + filter.value + "%'";
                }
            }
            else if (filter.operator == "gte")
                filter.operatorSign = ">=";
            else if (filter.operator == "gt")
                filter.operatorSign = ">";
            else if (filter.operator == "lte")
                filter.operatorSign = "<=";
            else if (filter.operator == "lt")
                filter.operatorSign = "<";
            else if (filter.operator == "isnull")
                filter.operatorSign = "= NULL";
            else if (filter.operator == "isnotnull")
                filter.operatorSign = "!= NULL";
            else if (filter.operator == "isempty")
                filter.operatorSign = "= ''";
            else if (filter.operator == "isnotempty")
                filter.operatorSign = "!= ''";
        }
    }

    /**Funkcija se kliče ob kliku na gumb z ikono 'X' - počisti se izbran filter */
    public clearFilter(filter: any, filterService: FilterService) {
        this.appliedFilters = this.appliedFilters.filter(x => x.field != filter.field);

        this.gridState.filter.filters = this.appliedFilters.map(f => isCompositeFilterDescriptor(f) ? f.filters : [f]).reduce((p, n) => p.concat(n), []);
        this.localStorageService.setLocalStorageData(this.gridSettings.ComponentName, this.gridState);

        this.gridView = process(this.data, this.gridState);
        this.shownRowsCount = this.gridView.data.length;

        //pošljemo vse sortirane in filtrirane podatke
        this.emitAllOrderedData(this.gridState);

        this.getSum();
        this.mySelection = [];
    }

    /**Funkcija ob kliku na gumb odstrani vse izbrane filtre */
    public clearAllFilters() {
        this.gridState.filter = undefined;
        this.localStorageService.setLocalStorageData(this.gridSettings.ComponentName, this.gridState);
        this.appliedFilters.length = 0;

        this.gridView = process(this.data, this.gridState);
        this.shownRowsCount = this.gridView.data.length;

        //pošljemo vse sortirane in filtrirane podatke
        this.emitAllOrderedData(this.gridState);

        this.getSum();
        this.mySelection = [];
    }

    public saveFilter() {
        this.localStorageService.setLocalStorageData(this.gridSettings.ComponentName, this.gridState);

        this.messageService.showMessage({ content: "Success", type: MessageType.info });
    }

    /**Funkcija se kliče ob kliku na checkbox v headerju tabele - izberejo se vse vrstice */
    public selectAllRows(field?: string) {
        //preverimo če shranjujemo checkbox value
        if (field != undefined) {
            this.closeEditor(this.grid);

            //nastavimo vrednosti vseh checkboxov
            for (let item of this.gridView.data) {
                item[field] = this.allSelectedSave[field];
            }

            //pošljemo seznam vseh vrstic
            this.operationEvent.emit({ operation: 'saveAll', dataItem: this.gridView.data });
        }
        else {
            this.closeEditor(this.grid);

            if (this.allSelected == true) {
                let start = this.gridState.skip;
                let end = this.gridState.skip + this.gridView.data.length;

                for (let index = start; index < end; index++) {
                    this.mySelection.push(index);
                }
            }
            else {
                this.mySelection = [];
            }

            this.operationEvent.emit({ operation: 'selectedKeysChanged', dataItem: undefined, selection: this.mySelection });
        }
    }

    /**Funkcija ki se kliče ob kliku na katerikoli checkbox v stolpcu s checkboxi - izbere se vrstica*/
    public selectRow(rowIndex: number, dataItem?: any) {
        if (dataItem != undefined) {
            this.operationEvent.emit({ operation: 'save', dataItem: dataItem });
        }
        else {
            if (this.mySelection.filter(x => x == rowIndex).length > 0) {
                this.mySelection = this.mySelection.filter(x => x != rowIndex);
            }
            else {
                this.mySelection.push(rowIndex);
            }
            this.operationEvent.emit({ operation: 'selectedKeysChanged', dataItem: undefined, selection: this.mySelection });
            this.operationEvent.emit({ operation: 'selectionChanged', dataItem: this.data[rowIndex], selection: this.mySelection });
        }
    }

    // Pridobim vse filtrirane in sortirane podatke
    public allData(): ExcelExportData {
        const result: ExcelExportData = {
            data: process(this.data, { sort: this.gridState.sort, filter: this.gridState.filter }).data,
        };
        return result;
    }

    /**Vrne vse vrstice sortirane in filtrirane ne glede na stran ali število vrstic na strani */
    public emitAllOrderedData(state: State): void {
        //začasni state s katerim bomo sortirali in pošiljali sortirane podatke
        let tmpState: any = Object.assign({}, state);
        //spremenimo da sortira celotno polje (in ne samo vidno stran)
        tmpState.skip = 0;
        tmpState.take = this.data.length;

        //pridobimo sortirano, filtrirano polje
        let orderedData: any = process(this.data, tmpState);
        this.allRowsCount = orderedData.total;

        //pošljemo filtrirane, sortirane podatke 

        this.operationEvent.emit({ operation: 'viewChange', dataItem: orderedData.data });
    }

    /**Funkcija ki se kliče ob kliku na gumb za shranjevanje priponke - če je tip stolpca file */
    public async downloadFile(dataItem: dtoDocument) {
        await this.service.getDocumentById(dataItem['docId']).toPromise()
        .then((resp) => {
            var binary = atob(String(resp['pdfData']).replace('/\s/g', ''));
            var len = binary.length;
            var buffer = new ArrayBuffer(len);
            var view = new Uint8Array(buffer);
            for (var i = 0; i < len; i++) {
                view[i] = binary.charCodeAt(i);
            }
    
            var blob = new Blob([view], { type: 'application/pdf' });
            var url = URL.createObjectURL(blob);
    
            var anchor = document.createElement("a");
            anchor.download = resp["erpkey"] + "_" + resp["subscriber"] + " [" + newGuid() + "].pdf";
            anchor.href = url;
            anchor.click();

            //window.open(url, "_blank");
        })
        .catch((err) => {            
            this.messageService.showMessage({content: 'errGettingDocument', type: MessageType.info});
        });
    }

    /**Pridobitev sume stolpcev */
    public getSum() {
        this.aggregates = [];

        if (this.gridView.data.length > 0) {

            for (let dataField of this.gridSettings.Fields) {
                if (dataField.FieldSum == true)
                    this.aggregates.push({ field: dataField.FieldName, aggregate: 'sum' });
            }
            //agregates
            this.total = aggregateBy(this.data, this.aggregates);
        }
        else this.total = [];
    }

    //#endregion

    //#region funkcije ter nastavitve tabele preko spustnega seznama

    /**Odpre oz. zapre pojavno okno ob kliku na tri pike */
    public popupVisible = false;
    @ViewChild('anchor', { static: true }) public anchor: ElementRef;
    @ViewChild('popup', { read: ElementRef, static: true }) public popup: ElementRef;
    /**Margin popup-a s podrobnostmi uporabnika */
    public settingPopupMargin = { horizontal: -247, vertical: 0 };

    public toggle(show?: boolean): void {
        this.popupVisible = show !== undefined ? show : !this.popupVisible;
    }

    /*@HostListener('document:click', ['$event'])
    public documentClick(event: any): void {
        if (!this.contains(event.target)) {
            this.toggle(false);
        }
    }

    private contains(target: any): boolean {
        return this.anchor.nativeElement.contains(target) ||
            (this.popup ? this.popup.nativeElement.contains(target) : false);
    }*/
    //#endregion

    //@HostListener('document:click', ['$event'])
    //public documentClick(event: any): void {
    //    console.log(event.target);
    //    if ((event.target.className.includes('ng-star-inserted') && event.target.nodeName == "TD") || (event.target.className.includes('ng-star-inserted') && event.target.nodeName == "P")) {
    //    //if ((event.target.nodeName == "TD" && event.target.className == "ng-star-inserted k-state-focused") || (event.target.nodeName == "P" && event.target.className == "ng-star-inserted") || (event.target.nodeName == "TD" && event.target.className == "ng-star-inserted")) {
    //        this.tabIndexVal = true
    //    } else {
    //        this.tabIndexVal = false;
    //    }
    //}

    /* Prilagajanje zadnjega stolpca v tabeli. */

    //public resizeLastColumn(e) {

    //    let grid = e.sender;
    //    let gridHeaderTable = grid.thead.parent();
    //    let gridBodyTable = grid.tbody.parent();

    //    if (gridBodyTable.width() < grid.wrapper.width() - kendo.support.scrollbar()) {

    //    }

    //}

    filterDataForLoop(field: ComponentFieldDTO) {
        return field.FieldVisible != false;
    }

}
