import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { dtoLicense } from 'app/common/dtos/dtoLicense';
import { LoadingService } from 'app/Core/CoreServices/loading-services/loading.service';
import { MessageType } from 'app/Core/CoreServices/message-services/message';
import { MessageService } from 'app/Core/CoreServices/message-services/message.service';
import { DataEditService } from 'app/services/dataEditService/data-edit.service';
import { LoginService } from 'app/services/loginService/login.service';
import { NavigationService } from 'app/services/navigation-services/navigation.service';
import { localservices } from 'googleapis/build/src/apis/localservices';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
    public auto_login: boolean = false;
    public auto_send: boolean = false;

    public licensed: boolean = false;
    public licKey: string = "";

    constructor(private service: DataEditService, private loadingService: LoadingService, public loginService: LoginService,
        private messageService: MessageService, private translate: TranslateService, private navigationService: NavigationService) { }

    ngOnInit(): void {
        if (Date.parse(this.loginService.user.tokenValidTo.toString()) < new Date(Date.now()).getTime()) {
            this.loginService.doLoginFromSessionStorage();
        }

        this.checkForLicense();

        if (localStorage.getItem('auto_login') != null) {
            this.auto_login = localStorage.getItem('auto_login').toLowerCase() == "true";
        }

        if (localStorage.getItem('auto_send') != null) {
            this.auto_send = localStorage.getItem('auto_send').toLowerCase() == "true";
        }
    }

    checkForLicense() {
        this.loadingService.busyYes();
        this.service.checkLicense().toPromise()
            .then((resp) => {
                if (resp['licKey'] != null || resp['licKey'] != undefined) {
                    this.licKey = resp['licKey'];
                    this.licensed = true;
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.loadingService.busyNo();
            });
    }

    private registerLicense(licKey: string) {
        if (licKey != "") {
            this.loadingService.busyYes();
            this.service.registerLicense(licKey).toPromise()
                .then((resp) => {
                    this.licensed = true;
                    this.messageService.showMessage({ content: 'licRegistered', type: MessageType.success });
                    localStorage.setItem("licensed", "true");
                })
                .catch((err) => {
                    if (err.status == 400 && err.error != "") {
                        this.messageService.showMessage({ content: err.error, type: MessageType.error });
                    } else {
                        console.log(err);
                        this.messageService.showMessage({ content: err.error, type: MessageType.error });
                    }
                })
                .finally(() => {
                    this.loadingService.busyNo();
                });
        }
        else {
            this.messageService.showMessage({ content: 'noLicGiven', type: MessageType.info });
        }
    }

    private deactivateLicense() {
        this.loadingService.busyYes();
        this.service.deactivateLicense().toPromise()
            .then((resp) => {
                if (resp == null) {
                    throw Error()
                }
                else {
                    this.messageService.showMessage({ content: 'LicDeactivated', type: MessageType.info });
                    this.licensed = false;
                    this.licKey = "";
                    localStorage.removeItem("licensed");
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.status == 400 || err.status == 500) {
                    this.messageService.showMessage({ content: err.message, type: MessageType.error });
                } else {
                    this.messageService.showMessage({ content: 'licDeactErr', type: MessageType.warn });
                }
            })
            .finally(() => {
                this.loadingService.busyNo();
            });
    }

    private getLicense() {
        this.loadingService.busyYes();
        this.service.getLicenseFromTaxNumber().toPromise()
            .then((resp: dtoLicense) => {
                if (resp != null && resp['licKey'] != null && resp['licKey'] != "") {
                    this.licKey = resp.licKey;
                    this.messageService.showMessage({ content: 'licFound', type: MessageType.success });
                } else {
                    throw new Error('noLic');
                }
            })
            .catch((err) => {
                if (err['message'] == 'noLic') {
                    this.messageService.showMessage({ content: 'noLicFound', type: MessageType.info });
                } else {
                    this.messageService.showMessage({ content: err['message'], type: MessageType.error });
                }
            })
            .finally(() => {
                this.loadingService.busyNo();
            });
    }

    public auto_send_change() {
        this.auto_send = !this.auto_send;
        localStorage.setItem('auto_send', String(this.auto_send));
    }

    public auto_login_change() {
        this.auto_login = !this.auto_login;
        localStorage.setItem('auto_login', String(this.auto_login));

        if (this.auto_login) {
            localStorage.setItem('loginData', sessionStorage.getItem('loginData'));
        } else {
            localStorage.removeItem('loginData');
        }
    }
}
