import { Component, ElementRef, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'app/Core/CoreServices/message-services/message.service';
import { dtoBusUnit } from 'app/common/dtos/dtoBusUnit';
import { ComponentViewDTO } from 'app/Core/Common/ComponentViewDTO';
import { FormComponentView } from 'app/Core/Components/form-with-labels-v2/FormComponentView';
import { LookupItem } from 'app/Core/Components/grid-v2/LookupItem';
import { AppConfig } from 'app/Core/CoreServices/configuration-services/configuration.service';
import { DataEditService } from 'app/services/dataEditService/data-edit.service';
import { UnitsComponentView } from './unitsGridSettings';
import { MessageType } from 'app/Core/CoreServices/message-services/message';
import { LoginService } from 'app/services/loginService/login.service';
import { LoadingService } from 'app/Core/CoreServices/loading-services/loading.service';
import { UnitsFormSettings } from './unitsFormSettings';
import { NavigationService } from 'app/services/navigation-services/navigation.service';
import { isMobileMenu } from 'app/Core/CoreFunctions/HelperFunctions';
import { Loader } from '@googlemaps/js-api-loader';

@Component({
	selector: 'app-bus-units',
	templateUrl: './bus-units.component.html',
	styleUrls: ['./bus-units.component.css']
})
export class BusUnitsComponent implements OnInit {
	public map: google.maps.Map;
	public marker: google.maps.Marker;

	public unitsGridSettings: ComponentViewDTO = UnitsComponentView;
	public formCV: FormComponentView = UnitsFormSettings;
	public dataActive: LookupItem[] = [];
	public selectedData: any = -1;
	public unitsDialogVisible: boolean = false;
	public addUnitDialogVisible: boolean = false;
	public newUnit: boolean = false;

	public currUnit: dtoBusUnit = undefined;
	public allUnits: dtoBusUnit[] = [];
	public curIsActive: boolean = false;

	public isSmall: boolean = false;

	constructor(private messageService: MessageService, private translate: TranslateService, private service: DataEditService,
		private loginService: LoginService, private loadingService: LoadingService, private navigationService: NavigationService, private ngZone: NgZone) { }

	async ngOnInit() {
		if (Date.parse(this.loginService.user.tokenValidTo.toString()) < new Date(Date.now()).getTime()) {
			this.loginService.doLoginFromSessionStorage();
		}

		if (!this.loginService.isAdmin())
			this.navigationService.navigateToUrlWQueryParams('postponed');
		else {
			this.getLookupData();
			await this.getData();

			this.unitsGridSettings.height = (window.innerHeight - AppConfig.settings.headerHeight) * 0.7;
		}
	}

	initializeMap(lat: number, lng: number) {
		let loader = new Loader({
			apiKey: 'AIzaSyCcb42nkMaPzwPzCYs-wQA2prHVtMzy_8s'
		});
		loader.load().then(async () => {
			console.log("map loaded");

			this.marker = new google.maps.Marker({
				position: new google.maps.LatLng(0, 0),
				map: this.map,
			});

			let mrk;
			let zoom;
			let placeMarker;
			if (lat == 0 || lng == 0) {
				mrk = new google.maps.LatLng(46.056946, 14.505751);
				zoom = 8;
				placeMarker = false;
			} else {
				mrk = new google.maps.LatLng(lat, lng);
				zoom = 12;
				placeMarker = true;
			}

			this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
				center: mrk,
				zoom: zoom
			});

			let myLocBtn = document.createElement("button");
			myLocBtn.textContent = await this.translate.get("my_location").toPromise();
			myLocBtn.classList.add("custom-map-control-button");

			this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(myLocBtn);

			myLocBtn.addEventListener("click", () => {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(
						(position: any) => {
							const pos = {
								lat: position.coords.latitude,
								lng: position.coords.longitude,
							};

							this.map.setCenter(pos);
							this.map.setZoom(12);
							this.placeMarker(new google.maps.LatLng(pos.lat, pos.lng));
						},
						(err) => {
							this.messageService.showMessage({ content: "location_error", type: MessageType.error });
							console.log(err);
						}
					);
				} else {
					this.messageService.showMessage({ content: "no_goelocation_on_browser", type: MessageType.error });
				}

			});

			if (placeMarker)
				this.placeMarker(mrk);

			this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
				this.ngZone.run(() => {
					this.placeMarker(event.latLng);
				});
			});
		});
	}

	placeMarker(location: google.maps.LatLng) {
		// Remove existing marker
		if (this.marker) {
			this.marker.setMap(null);
		}

		this.marker = new google.maps.Marker({
			position: location,
			map: this.map,
		});

		// Save the lat and lng for later use
		const lat = location.lat();
		const lng = location.lng();
		console.log(`Marker placed at Lat: ${lat}, Lng: ${lng}`);
	}

	public async getLookupData() {
		this.dataActive = [
			{ label: await this.translate.get("all").toPromise(), value: -1 },
			{ label: await this.translate.get("active").toPromise(), value: true },
			{ label: await this.translate.get("inactive").toPromise(), value: false },
		];
	}

	/**Filtrira tabelo glede na polje 'active'*/
	public async filterData() {
		await this.getData().then(() => {
			if (this.selectedData != -1) {
				this.allUnits = this.allUnits.filter(el => el.active == this.selectedData);
			}
		}).catch((err) => {
			this.messageService.showMessage({ content: "errorFilterUsers", type: MessageType.error });
		});
	}

	public async getData() {
		try {
			this.loadingService.busyYes();

			if (Date.parse(this.loginService.user.tokenValidTo.toString()) < new Date(Date.now()).getTime()) {
				await this.loginService.doLoginFromSessionStorage();
			}

			await this.service.getAllBusUnits(false).toPromise()
				.then((val) => {
					this.allUnits = val;
					this.allUnits.forEach(el => {
						if (el.active) {
							el['activeText'] = "✔️";
						} else {
							el['activeText'] = "❌";
						}
					});
				})
				.finally(() => {
					this.loadingService.busyNo();
				});
		} catch (err) {
			this.loadingService.busyNo();
		}
	}

	async operationHandler({ operation, dataItem, selection }) {
		switch (operation) {
			case 'selectionChanged': {
				this.currUnit = dataItem;
				break;
			}
			case 'remove': {
				if (!dataItem['active']) {

					this.loadingService.busyYes();
					await this.service.deleteBusUnit(dataItem['busUnitId']).toPromise()
						.then(() => {
							this.messageService.showMessage({ content: 'unitRemoved', type: MessageType.success });
							this.getData();
						})
						.catch((err) => {
							this.messageService.showMessage({ content: err.message, type: MessageType.error });
						}).finally(() => {
							this.loadingService.busyNo();
						});
				} else {
					this.messageService.showMessage({ content: 'errActiveUnit', type: MessageType.error });
				}

				break;
			}
			case 'dblClick': {
				this.showDialog(false);
				break;
			}
		}
	}

	/**
	 * Prikaz dialoga za dodajanje in posodablanje enot
	 * @param add če je TRUE se doda nova enota
	 */
	public async showDialog(add?: boolean) {
		this.unitsDialogVisible = true;
		this.newUnit = add;
		if (add) {
			this.currUnit = new dtoBusUnit();
			this.currUnit.busUnitName = "";
			this.currUnit.active = false;
			this.currUnit.latitude = 0;
			this.currUnit.longitude = 0;
		} else {
			await this.service.getUnitById(this.currUnit.busUnitId).toPromise()
				.then((val) => {
					this.currUnit = val;
					this.formCV.dataItem = this.currUnit;
				})
				.catch((err) => {
					this.messageService.showMessage({ content: 'errCantGetUser', type: MessageType.error });
				});
		}

		this.initializeMap(this.currUnit.latitude, this.currUnit.longitude);

		this.formCV.dataItem = this.currUnit;

		this.formCV.formGroup.patchValue(this.currUnit);
	}

	public closeDialog() {
		this.unitsDialogVisible = false;
	}

	public async saveBusUnitData() {
		this.currUnit.latitude = this.marker.getPosition().lat();
		this.currUnit.longitude = this.marker.getPosition().lng();

		if (this.newUnit) {
			//DODAJANJE NOVE ENOTE
			this.currUnit.firmId = this.loginService.user.firmId;

			await this.service.createNewUnit(this.currUnit).toPromise()
				.then(async () => {
					this.messageService.showMessage({ content: 'saveSucessfull', type: MessageType.success });
					await this.getData();
					this.closeDialog();
					this.newUnit = false;
				})
				.catch((err) => {
					this.messageService.showMessage({ content: err['error'], type: MessageType.error });
				});
		} else {
			//POSODABLANJE OBSTOJEČE ENOTE
			await this.service.updateUnit(this.currUnit).toPromise()
				.then(() => {
					if (this.currUnit.active) {
						this.messageService.showMessage({ content: 'unitActive', type: MessageType.info });
					} else {
						this.messageService.showMessage({ content: 'unitUnactive', type: MessageType.info });
					}
					this.messageService.showMessage({ content: 'saveSucessfull', type: MessageType.success });
				})
				.catch((err) => {
					this.messageService.showMessage({ content: err['message'], type: MessageType.success });
				})
				.finally(async () => {
					await this.getData();
					this.closeDialog();
				});
		}
	}

	public refresh() {
		this.selectedData = -1;
		this.getData();
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event) {
		event.target.innerWidth;

		this.unitsGridSettings.height = (window.innerHeight - AppConfig.settings.headerHeight) * 0.7;
		this.isSmall = isMobileMenu();
	}
}
