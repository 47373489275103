import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { max } from 'rxjs/operators';
import { IAppConfig } from './app-config.model';



@Injectable()
export class AppConfig {

    static settings: IAppConfig;

    constructor(private http: Http) { }

    load() {
        const jsonFile = `assets/config/config.dev.json`;
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response) => {
                AppConfig.settings = <IAppConfig>response.json();
                resolve();
            }).catch((response: any) => {
                reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }

    getApiUrl() {
        const jsonFile = `assets/config/config.dev.json`;
        this.http.get(jsonFile).toPromise().then((response) => {
            AppConfig.settings = <IAppConfig>response.json();
            return AppConfig.settings.apiServer;
        }).catch((response: any) => {
            return '';
        });
    }

    getBaseHref() {
        const jsonFile = `assets/config/config.dev.json`;
        this.http.get(jsonFile).toPromise().then((response) => {
            AppConfig.settings = <IAppConfig>response.json();
            return AppConfig.settings.baseHref;
        }).catch((response: any) => {
            return '';
        });
    }
}
