import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(private router: Router, private route: ActivatedRoute) { }

    navigateToUrl(url: string) {
        this.router.navigate([url]);
    }

    navigateToUrlWQueryParams(url: string, qParams?: any) {
        if (qParams != undefined) {
            this.router.navigate([url], { queryParams: qParams });
        }
        else {
            this.router.navigate([url]);
        }
    }

    refreshPageContent() {
        //trenutna stran na kateri se nahajamo
        let currentPage: string = "." + this.router.url;
        let currentPageWOParams: string = this.router.url.split('?')[0];
        let params: any = {};
        for (let queryParamKey of this.route.snapshot.queryParamMap.keys) {
            params[queryParamKey] = this.route.snapshot.queryParamMap.get(queryParamKey);
        }
        //ko se spremeni trenutni izbrani klient, osvežimo trenutno komponento, da se pridobijo novi podatki
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            //Če vsebuje URL query parametre, jih je potrebno pravilno poslati v navigacijo

            this.router.navigate([currentPageWOParams], { queryParams: params });
        });

    }
}
