export class LookupItem {
    value: any;
    label: string;
    /**Uporabljeno, kjer je mogoče več hkratnih izbir */
    isSelected?: boolean;
    /**Cel objekt z vsemi ostalimi lastnostmi - za prikaz v dropdownu */
    fullObject?: any;
}

export class LookupCriteria {
    lookupSource: string; //Tip - stranka, skladišče.... Poimenovano po imenu tabele v bazi
    firmID?: number;
    contentFilter?: string;
    limit?: number;
    valueFilter?: any;
    /**Dinamičen parameter - za source, kjer je potrebno enostavno filtriranje (npr. pridobimo skaldišča poslovne enote. RowGuidBusUnit je v tem primeru param)*/
    param?: any;

    constructor(lookupSource: string) {
        this.lookupSource = lookupSource;
    }
}