import { Component, HostListener, OnInit } from '@angular/core';
import { ErrorKeys } from 'app/Core/Common/BaseClass';
import { isMobileMenu } from 'app/Core/CoreFunctions/HelperFunctions';
import { LoadingService } from 'app/Core/CoreServices/loading-services/loading.service';
import { LoginService } from 'app/services/loginService/login.service';
import { browserRefresh } from '../../app.component';

declare interface RouteInfo {
	path: string;
	title: string;
	icon: string;
	class: string;
	show: boolean;
	click: string;
}

export var ROUTES: RouteInfo[] = [
	{ path: '/devices', title: 'devices', icon: 'phonelink_setup', class: '', show: true, click: "" },  		// prikaz vseh naprav in povezav
	{ path: '/units', title: 'busUnits', icon: 'business_center', class: '', show: true, click: "" },          	// routa za poslovne enote
	{ path: '/documents', title: 'documents', icon: 'description', class: '', show: true, click: "" },  		// prikaz vseh dokumentov
	{ path: '/postponed', title: 'postponedDoc', icon: 'library_books', class: '', show: true, click: "" },   	// routa za odložene dokument 
	{ path: '/users', title: 'users', icon: 'groups', class: '', show: true, click: "" }, 			   			// prikaz vseh uporabnikov
	{ path: '/settings', title: 'settings', icon: 'settings', class: '', show: false, click: "" },	            // routa za nastavitve
];

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
	menuItems: any[];

	public isAdmin: boolean;
	public isMobile: boolean;

	constructor(private loginService: LoginService, private loadingService: LoadingService) { }

	async ngOnInit() {
		let refresh = browserRefresh;

		if (refresh) {
			await this.loginService.doLoginFromSessionStorage();
		}

		this.isAdmin = this.loginService.isAdmin();
		this.isMobile = isMobileMenu();
		if (this.isMobile) {
			ROUTES[5].show = true;
		} else {
			ROUTES[5].show = false;
		}

		if (!this.isAdmin)
			this.menuItems = ROUTES.filter(el => el.title == "postponedDoc" || el.title == "settings" || el.title == "logout");
		else
			this.menuItems = ROUTES.filter(menuItem => menuItem);
	}

	public async  doLogout() {
		this.loadingService.busyYes();
        await this.loginService.doLogOut();
		this.loadingService.busyNo();
    }

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		event.target.innerWidth;
		
		this.isMobile = isMobileMenu();
		if (this.isMobile) {
			ROUTES[5].show = true;
		} else {
			ROUTES[5].show = false;
		}
	}
}
