/**
 * Razred, ki služi kot generalni response pri klicih - complex response
 */
export class BaseClass {
    Status: Boolean; //Status - OBSOLETE
    Errors: string[]; // -OBSOLETE

    Success?: boolean; //Uspešnost operacije
    ErrorCode?: number;
    ErrorMessage?: string; //Napaka
    ValidationErrors: ValidationError[]; //validacijske napake
    Data: any; //Property, ki vsebuje vrnjene podatke

    constructor() {
        this.Status = true;
        this.Errors = [];
        this.ValidationErrors = [];
        this.Data = null;
    }

    public addvalidationError(key: string, content: string, arrayIndex?: number) {
        if (arrayIndex == undefined)
            this.ValidationErrors.push({ Field: key, Content: content });
        else
            this.ValidationErrors.push({ ArrayIndex: arrayIndex, Field: key, Content: content });
    }

    public isValid(): Boolean {
        //if(Object.keys(this.ValidationErrors).length > 0) return false;
        if (this.ValidationErrors.length > 0) return false;
        return true;
    }
}

export class ValidationError {
    Field: string;
    Content: string;
    /**Za client side validacijo - npr: required, min, max... */
    ErrorKey?: ErrorKeys;
    ArrayIndex?: number;
}

export enum ErrorKeys {
    custom = 'custom',
    required = 'required',
    min = 'min',
    max = 'max'
}

/**
 *  Razred se uporablja za podajanje statusa in napak - info response
 */
export class ResponseMsgWCode {
    Success: Boolean;
    ErrorCode?: number;
    ErrorMessage?: string;
    Object?: any;

    ErrorMsg?: string;
    ObjectId?: any;
    /**Vrnemo lahko tudi validacijske napake, ki se vežejo direktno na polje*/
    ValidationErrors?: ValidationError[];
}