/**
 * @param str podan string za formatiranje
 * @param val parametri za vstavlanje v str
 * @returns string z vstavljenimi parametri
 */
export function StringFromat(str: string, ...val: any[]): string {
    for (let i = 0; i < val.length; i++) {
        str = str.replace(`{${i}}`, val[i]);
    }
    return str;
}

/**Preveri podan string in vrne array ujemajočih delov emaila*/
export function VerifyEmail(email: string) { //returns array with parts of email ([0] -> full match, [1] -> surrname, [2] -> null?, [3] -> top-level domain)
    var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return !!email && typeof email === 'string' && email.match(emailRegex);
}

/**
 * @param email string nad katerim se izvede preverjanje
 * @returns true/false
 */
export function CheckEmail(email: string): boolean {
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return filter.test(email);
}

/**
 * 
 * @param email string nad katerim se izvede preverjanje
 * @returns true/false
 */
export function ValidateEmail(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
