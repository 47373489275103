
import { Component, OnInit, EventEmitter, SimpleChanges, Output, Input, OnChanges, ViewChild, ViewContainerRef, HostListener, ElementRef, ViewChildren, QueryList, AfterContentInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FormComponentView } from './FormComponentView';
import { ErrorKeys } from '../../Common/BaseClass';


@Component({
    selector: 'app-form-with-labels-v2',
    templateUrl: './form-with-labels-v2.component.html',
    styleUrls: ['./form-with-labels-v2.component.css']
})
export class FormWithLabelsV2Component implements OnInit, OnChanges, AfterContentInit {

    //#region VARIABLES
    @Input() genericComponentName: string;
    @Input() style: string;
    @Input() formComponentView: FormComponentView;
    /**Prvotne nastavitve - uporabljeno pri modificiranju component view-a*/
    initialFormComponentView: FormComponentView = undefined;
    /**Validacijske napake na nivoju komponente - custom validacije */
    @Input() clientValidationErrors: any[] = [];
    /**Validacijske napake forme */
    public formValidationErrors: any = {};

    public opened: boolean = false;

    //spremeniljivka za tabIndex
    public tabIndexFinal: number;

    //Urejanje forme
    public editComponentFieldVisible: boolean = false;

    //#endregion VARIABLES

    //#region events
    @Output() operationEvent: EventEmitter<{ operation: string, dataItem?: any, changedProperty?: string }> = new EventEmitter();
    //#endregion events    

    @ViewChild('appendTo', { read: ViewContainerRef, static: false }) public appendTo: ViewContainerRef;

    @ViewChildren('formInput') formInput: QueryList<any>;

    public onKeydown(event: any) {
        switch (event.key) {
            case 'Enter': {
                if (event.srcElement.id != "formCancelButton" && event.srcElement.id != "formConfirmButton") {
                    let element: string = event.srcElement.outerHTML;
                    //če smo v comboboxu in pritisnemo enter preprečimo shranjevanje - ker se combobox s enter zapre
                    if (!element.includes('role="combobox"')) {
                        this.saveHandler();
                    }
                }
                break;
            }
            case 'Escape': {
                this.cancelHandler();
            }
        }
    }

    constructor(private sanitization: DomSanitizer) { }

    ngOnInit() {
        if (this.formComponentView != undefined) {
            if (this.formComponentView.formGroup === undefined) {
                //this.formGroup = this.createFormGroup(this.formComponentView.dataItem);
                this.formComponentView.formGroup = this.createFormGroup(this.formComponentView.dataItem);
            } else {
                this.formComponentView.formGroup = this.formComponentView.formGroup;
            }
        }

        console.log(this.formComponentView.dataItem);


    }

    ngAfterContentInit() {
        //var tmp = document.getElementsByTagName("input")[0];
        //tmp.focus();
        //tmp.select();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['formComponentView']) {
            //change tabIndex if tabMoveType == 1
            if (this.formComponentView.TabMoveType == 1) {
                let regex = /\dfr/g;
                let columnNumber = this.formComponentView.Tabs[0].Groups[0].CSSStyle.match(regex).length;
                console.log(columnNumber);

                let allFields = this.formComponentView.Tabs[0].Groups[0].Fields;
                let numOfFields = allFields.length - 1;
                let tabIndexNum = 1;

                for (var i = 0; i < columnNumber; i++) {
                    let j = i;

                    while (j <= numOfFields) {

                        allFields[j].tabIndex = tabIndexNum;
                        tabIndexNum++;
                        j += columnNumber;
                    }

                }

                this.formComponentView.Tabs[0].Groups[0].Fields = allFields;
                this.tabIndexFinal = tabIndexNum + 1;
                //console.log(allFields);
                //console.log(this.formComponentView.Tabs[0].Groups[0].Fields);


            }

            if (this.formComponentView.id == undefined)//Če ni componentView shranjen v DB, ga ne pustim modificirati
                this.formComponentView.CanEditComponentView == undefined;

            if (this.formComponentView.formGroup === undefined) {
                this.formComponentView.formGroup = this.createFormGroup(this.formComponentView.dataItem);
            } else {
                this.formComponentView.formGroup = this.formComponentView.formGroup;
            }

        }
        else if (changes['clientValidationErrors']) {
            this.clientValidationErrors = changes['clientValidationErrors'].currentValue;
        }
    }

    // Dobim style
    public getStyle(style: string) {
        return this.sanitization.bypassSecurityTrustStyle(style);
    }

    //#region HANDLERS

    public saveHandler(): void {
        // Preverim če je forma validna in spremenjena
        if (this.formComponentView.formGroup.valid) {
            this.formValidationErrors = {};
            // Pošljem dogodek za shranjevanje
            this.operationEvent.emit({ operation: 'save', dataItem: this.formComponentView.formGroup.value });
        }
        else {
            this.getFormValidationErrors();
        }
    }

    public cancelHandler(): void {
        // Pošljem dogodek za preklic
        this.operationEvent.emit({ operation: 'cancel' });
    }

    public removeHandler() {

        this.operationEvent.emit({ operation: 'remove', dataItem: this.formComponentView.formGroup.value });
        this.opened = false;
    }

    public openCloseDialog(vrednost: boolean) {
        if (!this.formComponentView.DeleteWOConfirmation) {
            if (vrednost) {
                this.opened = true;
            } else {
                this.opened = false;
            }
        } else {
            this.operationEvent.emit({ operation: 'remove', dataItem: this.formComponentView.formGroup.value });
        }
    }

    public dropdownHandler($event: any, item: string): void {
        const control: AbstractControl = this.formComponentView.formGroup.get(item);
        if (!control.value || control.value === ' ') {
            if ($event === 'focus') {
                control.setValue(' ');
            } else {
                control.setValue(undefined);
            }
        }
    }

    // Metoda, ki spremeni vrednost form skupine
    public updateValueHandler(formControl: string, value: string): void {
        this.formComponentView.formGroup.get(formControl).patchValue(value);
        this.formComponentView.formGroup.markAsDirty();
        this.operationEvent.emit({ operation: 'propertyChange', dataItem: this.formComponentView.formGroup.value, changedProperty: formControl });
    }

    public getFormValidationErrors() {
        /**Za prikaz notificationov*/
        let tmpErrorList: any[] = [];

        this.formValidationErrors = {};
        Object.keys(this.formComponentView.formGroup.controls).forEach(key => {

            const controlErrors: ValidationErrors = this.formComponentView.formGroup.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    //console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                    let tmpValidationError: any = { Field: key, Content: '', ErrorKey: ErrorKeys[keyError] };
                    this.formValidationErrors[key] = tmpValidationError;
                    tmpErrorList.push(tmpValidationError);

                    //this.messageService.showMessage({ type: MessageType.error, content: tmpValidationError.Field + " " + tmpValidationError.ErrorKey });
                });
            }
        });

        console.log(this.formValidationErrors);
    }

    //#endregion HANDLERS

    public createFormGroup(dataItem: any): FormGroup {
        if (dataItem == undefined)
            dataItem = {}; //Prazna inicializacija
        const group = new FormGroup({});
        for (let tab of this.formComponentView.Tabs) {
            for (let tmpgroup of tab.Groups) {
                //console.log(tmpgroup);
                for (let formField of tmpgroup.Fields) {
                    let key: string = formField.FieldName;

                    let validators: any[] = [];

                    //FrontEnd validacije
                    if (formField.FieldRequired)
                        validators.push(Validators.required);

                    switch (formField.FieldType) {
                        case 'time':
                        case 'date': {
                            if (dataItem[key] != undefined)
                                group.addControl(key, new FormControl(new Date(dataItem[key]), validators));
                            else group.addControl(key, new FormControl(null, validators));
                            break;
                        }
                        case 'dateTime': {
                            if (dataItem[key] != undefined) {
                                group.addControl(key, new FormControl(new Date(dataItem[key]), validators));
                            }
                            else group.addControl(key, new FormControl(null, validators));
                            break;
                        }
                        default:
                            {
                                //Glede na tip, nastavim pravilno začetno vrednost, če ta ni podana
                                if (dataItem[key] != undefined)
                                    group.addControl(key, new FormControl(dataItem[key], validators));
                                else {
                                    switch (formField.FieldType) {
                                        case 'image':
                                        case 'input':
                                        case 'maskedInput':
                                        case 'numericInput':
                                        case 'multilineInput':
                                        case 'numericIntegerInput':
                                            group.addControl(key, new FormControl(null, validators)); break;
                                        case 'dropdown':
                                            group.addControl(key, new FormControl(null, validators)); break;
                                        case 'checkbox':
                                            group.addControl(key, new FormControl(false, validators)); break;
                                    }
                                }
                            }
                    }
                }
            }
        }
        return group;
    }
}
