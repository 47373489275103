import { FormComponentView } from "../../Core/Components/form-with-labels-v2/FormComponentView";

export const DevicesFormSettings: FormComponentView = {
    ComponentName: 'DeviceForm',
    ComponentType: 1,
    Tabs: [
        {
            TabName: '',
            Groups: [
                {
                    GroupName: '',
                    CSSStyle: 'width: inherit; grid-template-columns: 1fr;',
                    Fields: [
                        { FieldName: 'deviceName', FieldType: 'input', FieldRequired: true, FieldEditable: false },
                        { FieldName: 'busUnitName', FieldType: 'dropdown' }
                    ]
                }
            ]
        }
    ],
    Add: false,
    Edit: true,
    Delete: false,
    CommandsVisible: true,
    DisplayType: 1
}