export class dtoBusUnit {
    constructor() {
        this.busUnitId = this.firmId = this.busUnitName = this.createdAt = this.active = this.latitude = this.longitude = undefined;
    }

    busUnitId: number;
    firmId: number;
    busUnitName: string;
    createdAt: Date;
    active: boolean;
    latitude: number;
    longitude: number;
}
