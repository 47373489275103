import { ComponentViewDTO } from '../../Core/Common/ComponentViewDTO';

export const DocComponentView: ComponentViewDTO = {
    ComponentType: 1,
    ComponentName: 'DocPostponedGridView',
    Tabs: [
        {
            TabName: '',
            Groups: [
                {
                    GroupName: '',
                    CSSStyle: '',
                    Fields: [
                        { FieldName: 'erpkey', FieldType: 'input' },
                        { FieldName: 'docDate', FieldType: 'dateTime', FieldLabel: 'docDate' },
                        { FieldName: 'amount', FieldType: 'numericInput' },
                        { FieldName: 'createdAt', FieldType: 'dateTime' },
                        { FieldName: 'customerTitle', FieldType: 'input' },
                        { FieldName: 'firmDeviceName', FieldType: 'input' },
                        { FieldName: 'signDeviceName', FieldType: 'input' },
                        { FieldName: 'subscriber', FieldType: 'input' },
                        { FieldName: 'note', FieldType: 'input' },
                        { FieldName: 'docGroup', FieldType: 'input' },
                        { FieldName: 'pdfData', FieldType: 'file' }
                    ]
                }
            ]
        }
    ]
    ,
    Add: false,
    Edit: false,
    Delete: false,
    EnableExport: true,
    isSelectable: false,
    pageable: true,
    groupable: false
};