import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageDialogTypes } from './message-dialog-types.enum';
import { DialogResultTypes } from './dialog-result-types.enum';
import { MessageService } from '../../CoreServices/message-services/message.service';

@Component({
    selector: 'app-msg-dialog',
    templateUrl: './msg-dialog.component.html',
    styleUrls: ['./msg-dialog.component.css']
})
export class MsgDialogComponent implements OnInit {

    dialogClasses = {
        info: false,
        error: false,
        question: false,
    }

    //Komponenta deluje v povezavi z message service.
    //V servisu se kliče funkcija showDialog, ki nastavi pravilen property, na katerega je zvezana ta komponenta. 

    constructor(public messageService: MessageService) { }

    ngOnInit() {
        this.dialogClasses = {
            info: this.messageService.msgDialog.type == "info",
            error: this.messageService.msgDialog.type == "error",
            question: this.messageService.msgDialog.type == "question",
        }
    }

    OnDialogeClose(status: string) {
        let resStatus: DialogResultTypes = undefined;
        switch (status) {
            case 'No': resStatus = DialogResultTypes.No; break;
            case 'Yes': resStatus = DialogResultTypes.Yes; break;
            case 'OK': resStatus = DialogResultTypes.OK; break;
            case 'Cancel': resStatus = DialogResultTypes.Cancel; break;
        }
        this.messageService.msgDialogResultEvent(resStatus);
    }
}

export class msgDialogParam {
    /**Lahko je podan enum od prevodov - prevede se v msg-dialog.component.css*/
    title: string;
    /**Lahko je podan enum od prevodov - prevede se v msg-dialog.component.css*/
    message: string;
    type: MessageDialogTypes;
    /**oblikovan HTML - za izpis kompleksenjših obvestil*/
    innerHTML?: string;
}
