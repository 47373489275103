import { AppConfig } from './Core/CoreServices/configuration-services/configuration.service';
import { isNullOrEmpty } from './Core/CoreFunctions/HelperFunctions';

export function getAPIUrl(endpoint: string, params?: string[], queryParams?: queryParam[]) {
    var api = AppConfig.settings.apiServer.apiWebAddress;

    if (localStorage.getItem("api_url") != null && localStorage.getItem("api_url") != "") {
        api = localStorage.getItem("api_url");
    }

    let url: string = "";
    if (params == undefined)
        url = api + endpoint;
    else {        
        url = api + endpoint;
        params.forEach(param => {
            url += '/' + param;
        });
    }

    if (isNullOrEmpty(queryParams) == false) {
        url += '?';
        url += queryParams.map(x => x.name + '=' + x.value).join('&');
    }

    console.log(url);
    
    return url;
}

/**
 * 
 * @param endpoint API
 * @param params KeyValue podani parametri
 */
export function getAPIUrlWQueryParams(endpoint: string, params?: queryParam[]) {
    var api = AppConfig.settings.apiServer.apiWebAddress;

    if (localStorage.getItem("api_url") != null && localStorage.getItem("api_url") != "") {
        api = localStorage.getItem("api_url");
    }

    if (params == undefined)
        return api + endpoint;
    else {
        let url = api + endpoint + '?';
        url += params.map(x => x.name + '=' + x.value).join('&');
        return url;
    }
}

export class queryParam {
    name: string;
    value: any;
}

//#region CORE ENDPOINTS

export const SettingsAPIFunctions = {
    userProfileRightSetting: "" + 'userProfileRightSetting',
    userGridColumnSettings: "" + 'userGridColumnSettings'
}

//#endregion


//#region ENDPOINTS

export const LoginServiceEndpoint = {
    login: "login"
}

export const DocumentServiceEndpoint = {
    getDocument: "Document",
    getAllDocuments: "Document/All",
    getDocumentStatus: "Document/status",
    getDeviceNames: "Document/DeviceNames",
    getDocumentById: "Document/Data",
    getPostponedDocs: "Document/postponedDocumentsInBranch",
    getDataForFilter: "Document/dataForPostponedFilters",
    sendPostponedDocument: "Document/PushPostponedDocument"
}

export const DeviceServiceEndpoint = {
    postFirmDevice: "Device/FirmDevice",
    getAllFirmDevices: "Device/AllFirmDevices",
    getAllSignDevices: "Device/AllSignDevices",
    getAllSignDevicesInBusUnit: "Device/AllSignDevicesInBusUnit",
    deleteFirmDevice: "Device/DeleteFirmDevice",
    deleteSignDevice: "Device/DeleteSignDevice",
    updateFirmDevice: "Device/UpdateFirmDevice",
    updateSignDevice: "Device/UpdateSignDevice",
    device: "Device"
}

export const FirmSignDeviceServiceEndpoint = {
    firmSignDevices: "FirmSignDevices"
}

export const UserServiceEndpoint = {
    user: "Users"
}

export const LicenseServiceEndpoint = {
    license: "License",
    checkLic: "License/check",
    deactivateSignDeviceLicense: "License/deactivateSignDevice",
}

export const BusUnitEndpoint = {
    busUnit: "BusUnit",
    busUnitsForFirm: "BusUnit/GetBusUnits"
}

//#endregion