import { Component, OnInit } from '@angular/core';
import { dtoDocument } from '../../common/dtos/dtoDocument';
import { ComponentViewDTO } from '../../Core/Common/ComponentViewDTO';
import { AppConfig } from '../../Core/CoreServices/configuration-services/configuration.service';
import { LoadingService } from '../../Core/CoreServices/loading-services/loading.service';
import { MessageService } from '../../Core/CoreServices/message-services/message.service';
import { DataEditService } from '../../services/dataEditService/data-edit.service';
import { DocComponentView } from './docGridSettings';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'app/services/loginService/login.service';
import { MessageType } from 'app/Core/CoreServices/message-services/message';
import { LookupItem } from 'app/Core/Components/grid-v2/LookupItem';
import { StringFromat } from 'app/services/helperFunctions/functions';
import { NavigationService } from 'app/services/navigation-services/navigation.service';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
    public searchAlias: string = "";
    public docs: dtoDocument[] = [];
    public currDoc: dtoDocument = undefined;

    public docStatuses: LookupItem[] = [];
    public firmDevicesData: LookupItem[] = [];
    public signDevicesData: LookupItem[] = [];
    public docGroupData: LookupItem[] = [];

    public selectedStatus: number = -1;
    public selectedFirmDevice: any = -1;
    public selectedSignDevice: any = -1;
    public selectedDocGroup: any = -1;

    public docGridSettings: ComponentViewDTO = DocComponentView;
    public docFilesDialogVisible: boolean = false;

    constructor(private service: DataEditService, private loadingService: LoadingService,
        private messageService: MessageService, private translate: TranslateService, private loginService: LoginService, private navigationService: NavigationService) { }

    async ngOnInit() {
        if (Date.parse(this.loginService.user.tokenValidTo.toString()) < new Date(Date.now()).getTime()) {
            this.loginService.doLoginFromSessionStorage();
        }

        if (!this.loginService.isAdmin())
            this.navigationService.navigateToUrlWQueryParams('postponed');
        else {
            this.getDocStatuses();
            await this.getDeviceNamesForFilter();
            await this.getData();

            this.docGridSettings.height = (window.innerHeight - AppConfig.settings.headerHeight) * 0.8;
        }
    }

    public async getDocStatuses() {
        this.docStatuses = [
            { label: await this.translate.get("all").toPromise(), value: -1 },
            { label: await this.translate.get("created").toPromise(), value: 0 },
            { label: await this.translate.get("sentToDevice").toPromise(), value: 1 },
            { label: await this.translate.get("signed").toPromise(), value: 2 },
            { label: await this.translate.get("rejected").toPromise(), value: 3 },
            { label: await this.translate.get("canceled").toPromise(), value: 4 },
            { label: await this.translate.get("postponed").toPromise(), value: 5 },
            { label: await this.translate.get("singedButNotArchived").toPromise(), value: 6 },
            { label: await this.translate.get("singedAndArchived").toPromise(), value: 10 }
        ];
    }

    public async getDeviceNamesForFilter() {
        await this.service.getDeviceNamesForFilter().toPromise()
            .then(async res => {
                this.firmDevicesData = [];
                this.firmDevicesData.push({ label: await this.translate.get("all").toPromise(), value: -1 });
                res['firmDevices'].forEach(el => {
                    this.firmDevicesData.push({ label: el, value: el });
                });

                this.signDevicesData = [];
                this.signDevicesData.push({ label: await this.translate.get("all").toPromise(), value: -1 });
                res['signDevices'].forEach(el => {
                    this.signDevicesData.push({ label: el, value: el });
                });

                this.docGroupData = [];
                this.docGroupData.push({ label: await this.translate.get("all").toPromise(), value: -1 });
                this.docGroupData.push({ label: "Sign", value: "Sign" });
                res['docGroups'].forEach(el => {
                    this.docGroupData.push({ label: el, value: el });
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    public async getData() {
        try {
            this.loadingService.busyYes();
           
            if (Date.parse(this.loginService.user.tokenValidTo.toString()) < new Date(Date.now()).getTime()) {
				await this.loginService.doLoginFromSessionStorage();
			}

            await this.service.getAllDocuments().toPromise()
                .then((res) => {
                    this.docs = res;

                    this.docs.forEach(el => {
                        if (el.status == 0) el['statusText'] = "(0) " + this.translate.instant('created');
                        else if (el.status == 1) el['statusText'] = "(1) " + this.translate.instant('sentToDevice');
                        else if (el.status == 2) el['statusText'] = "(2) " + this.translate.instant('signed');
                        else if (el.status == 3) el['statusText'] = "(3) " + this.translate.instant('rejected');
                        else if (el.status == 4) el['statusText'] = "(4) " + this.translate.instant('canceled');
                        else if (el.status == 5) el['statusText'] = "(5) " + this.translate.instant("postponed");
                        else if (el.status == 6) el['statusText'] = "(6) " + this.translate.instant("singedButNotArchived");
                        else if (el.status == 10) el['statusText'] = "(10) " + this.translate.instant('singedAndArchived');

                        if (el['signActivationUsername'] == null || el['signActivationUsername'] == undefined) {
                            el['signActivationUsername'] = "-";
                        }

                        if (el['locationName'] == null ||el['locationName'] == undefined) {
                            el['locationName'] = "-";
                        }                       

                        if (el.docGroup == undefined || el.docGroup.trim() == "") {
                            el.docGroup = "Sign";
                        }
                    });
                });

            this.loadingService.busyNo();
        }
        catch (err) {
            this.loadingService.busyNo();
        }
    }

    public async operationHandler({ operation, dataItem, selection }): Promise<void> {
        switch (operation) {
            case 'remove': {
                //brisanje dokumentov, ki niso podpisani ali odloženi
                if (dataItem['status'] !== 2 && dataItem['status'] !== 6 && dataItem['status'] !== 10) {
                    await this.service.deleteDocument(dataItem['docId']).toPromise()
                        .then(async () => {
                            var str = await this.translate.get('docRemoved').toPromise();
                            var msg: string = StringFromat(str, dataItem['docId'], dataItem['erpkey'])

                            this.messageService.showMessage({ content: msg, type: MessageType.info });
                        })
                        .catch((err) => {
                            this.messageService.showMessage({ content: 'error', type: MessageType.error });
                        })
                        .finally(() => {
                            this.getData();

                            this.selectedStatus = -1;
                            this.selectedFirmDevice = -1;
                            this.selectedSignDevice = -1;
                        });
                } else {
                    this.messageService.showMessage({ content: 'docDelErr', type: MessageType.error });
                }
                break;
            }
            // case 'dblClick': {
            //     console.log(dataItem);
            //     break;
            // }
        }
    }

    /**Filtrira tabelo dokumentov glede na izbran status*/
    public async filterData() {
        await this.getData().then(() => {
            if (this.selectedStatus != -1) {
                this.docs = this.docs.filter(el => el.status === this.selectedStatus);
            }
            if (this.selectedFirmDevice != -1) {
                this.docs = this.docs.filter(el => el['firmDeviceName'] === this.selectedFirmDevice);
            }
            if (this.selectedSignDevice != -1) {
                this.docs = this.docs.filter(el => el['signDeviceName'] === this.selectedSignDevice);
            }
            if (this.selectedDocGroup != -1) {
                this.docs = this.docs.filter(el => el['docGroup'] === this.selectedDocGroup);
            }
        }).catch((err) => {
            this.messageService.showMessage({ content: "errorFilterDocuments", type: MessageType.error });
        });
    }

    public async refresh() {
        await this.getDeviceNamesForFilter();

        this.getData();

        this.selectedStatus = -1;
        this.selectedFirmDevice = -1;
        this.selectedSignDevice = -1;
    }

    public onResize(event) {
        this.docGridSettings.height = (window.innerHeight - AppConfig.settings.headerHeight) * 0.8;
    }
}
