import { Component, OnInit } from '@angular/core';
import { dtoFirmDevice } from 'app/common/dtos/dtoFirmDevice';
import { dtoSignDevice } from 'app/common/dtos/dtoSignDevice';
import { dtoFirmSignDevice } from 'app/common/dtos/dtoFirmSignDevice';
import { AppConfig } from '../../Core/CoreServices/configuration-services/configuration.service';
import { ComponentViewDTO } from 'app/Core/Common/ComponentViewDTO';
import { LoadingService } from 'app/Core/CoreServices/loading-services/loading.service';
import { DataEditService } from 'app/services/dataEditService/data-edit.service';

import { SignDevicesGridSettings } from './signDevicesGridSettings';
import { FirmDevicesGridSettings } from './firmDevicesGridSettings';
import { LookupItem } from 'app/Core/Components/grid-v2/LookupItem';
import { FirmSignDevicesGridSettings } from './firmSignDevicesGridSettings';
import { FirmSignDevice } from 'app/common/objects/firmSignDevice';
import { LoginService } from 'app/services/loginService/login.service';
import { MessageService } from 'app/Core/CoreServices/message-services/message.service';
import { MessageType } from 'app/Core/CoreServices/message-services/message';
import { StringFromat } from 'app/services/helperFunctions/functions';
import { TranslateService } from '@ngx-translate/core';
import { FormComponentView } from 'app/Core/Components/form-with-labels-v2/FormComponentView';
import { DevicesFormSettings } from './devicesFormSettings';
import { dtoBusUnit } from 'app/common/dtos/dtoBusUnit';
import { NavigationService } from 'app/services/navigation-services/navigation.service';

@Component({
	selector: 'app-all-devices',
	templateUrl: './all-devices.component.html',
	styleUrls: ['./all-devices.component.css']
})
export class AllDevicesComponent implements OnInit {
	public deviceDialogVisible: boolean = false;
	public busUnits: LookupItem[] = [];
	public signDevices: dtoSignDevice[] = [];
	public firmDevices: dtoFirmDevice[] = [];
	public firmSignDevices: dtoFirmSignDevice[] = [];

	public selectedBusUnit: number;

	public signDeviceGridSettings: ComponentViewDTO = SignDevicesGridSettings;
	public firmDeviceGridSettings: ComponentViewDTO = FirmDevicesGridSettings;
	public firmSignDeviceGridSettings: ComponentViewDTO = FirmSignDevicesGridSettings;
	public formCV: FormComponentView = DevicesFormSettings;

	public selectedFirmDevice: dtoFirmDevice;
	public selectedSignDevice: dtoSignDevice;
	public selectedFirmSignDevice: dtoFirmSignDevice;

	public max_dev = 0;
	public curConnections: number;

	public greenYellowTreshold: number;
	public yellowRedTreshold: number;

	public showDescription: boolean = false;
	public btnDisabled: boolean = false;
	public type: number;

	public devDescriptionData: string;

	constructor(private service: DataEditService, private loadingService: LoadingService, public loginService: LoginService,
		private messageService: MessageService, private translate: TranslateService, private navigationService: NavigationService) { }


	ngOnInit(): void {
		if (Date.parse(this.loginService.user.tokenValidTo.toString()) < new Date(Date.now()).getTime()) {
			this.loginService.doLoginFromSessionStorage();
		}

		if (!this.loginService.isAdmin())
			this.navigationService.navigateToUrlWQueryParams('postponed');
		else {
			this.getData().then(() => {
				this.curConnections = this.firmSignDevices.length;

				this.greenYellowTreshold = Math.round(this.max_dev * 0.5); // do 50% licence je zeleno, od 50% do 75% je oranžno
				this.yellowRedTreshold = Math.round(this.max_dev * 0.75); // nad 75% je rdeče barve
			});

			this.getBusUnits();

			this.signDeviceGridSettings.height = (window.innerHeight - AppConfig.settings.headerHeight) * 0.4;
			this.firmDeviceGridSettings.height = (window.innerHeight - AppConfig.settings.headerHeight) * 0.4;
			this.firmSignDeviceGridSettings.height = (window.innerHeight - AppConfig.settings.headerHeight) * 0.45;
		}
	}

	public async getData(getSignDevices?: boolean, getFirmDevices?: boolean, getFirmSignDevices?: boolean) {
		try {
			this.loadingService.busyYes();

			if (Date.parse(this.loginService.user.tokenValidTo.toString()) < new Date(Date.now()).getTime()) {
				await this.loginService.doLoginFromSessionStorage();
			}

			if (getSignDevices || getSignDevices == null) this.signDevices = await this.service.getAllSignDevices().toPromise();
			if (getFirmDevices || getSignDevices == null) this.firmDevices = await this.service.getAllFirmDevices().toPromise();
			if (getFirmSignDevices || getSignDevices == null) this.firmSignDevices = await this.service.getAllFirmSignDevices().toPromise();

			await this.service.checkLicense().toPromise().then((resp) => {
				if (resp != null) {
					this.max_dev = resp["maxConCount"];
					this.btnDisabled = false;
				} else {
					this.max_dev = 0;
					this.btnDisabled = true;
				}
			});
		}
		catch (err) {
			this.messageService.showMessage({ content: err, type: MessageType.error })
		}
		finally {
			this.curConnections = this.firmSignDevices.length;
			this.loadingService.busyNo();
		}
	}

	public async getBusUnits() {
		await this.service.getAllBusUnits(true).toPromise()
			.then((res: dtoBusUnit[]) => {
				//res.sort((a, b) => (a.busUnitName.toLocaleLowerCase() > b.busUnitName.toLocaleLowerCase() ? 1 : -1));
				res.forEach((el: dtoBusUnit) => {
					this.busUnits.push({ label: el.busUnitName, value: el.busUnitId });
				});
			});
	}

	/**Poveže izbran firmDevice z izbranim signDevicom*/
	public async connectDevices() {
		if (this.selectedSignDevice != null && this.selectedFirmDevice != null) {
			await this.getData(false, false, true);
			var tmp = this.firmSignDevices.filter(el => el.firmDeviceId == this.selectedFirmDevice.firmDeviceId);

			if (this.firmSignDevices.find(el => el.firmDeviceId == this.selectedFirmDevice.firmDeviceId && el.rowGuidSignDevice == this.selectedSignDevice.rowGuidSignDevice)) {
				this.messageService.showMessage({ content: 'conExists', type: MessageType.error });
			} else if (tmp.length > 0) {
				this.messageService.showMessage({ content: 'canNotConnectMore', type: MessageType.error });
			} else {
				this.loadingService.busyYes();
				var fsd = new FirmSignDevice()
				fsd.firmId = this.loginService.user.firmId;
				fsd.firmDeviceId = this.selectedFirmDevice.firmDeviceId;
				fsd.RowGuidSignDevice = this.selectedSignDevice.rowGuidSignDevice;
				var resp = await this.service.createFirmSignDevice(fsd).toPromise()
					.then(async (resp) => {
						this.getData();
						var str = await this.translate.get('devicesConnected').toPromise();
						var msg = StringFromat(str, this.selectedFirmDevice.deviceName, this.selectedSignDevice.deviceName);
						this.messageService.showMessage({ content: msg, type: MessageType.success });
					})
					.catch(async (err) => {
						if (err['status'] == 405) {
							var str = await this.translate.get('licenceExceeded').toPromise();
							var msg = StringFromat(str, this.max_dev);
							this.messageService.showMessage({ content: msg, type: MessageType.error });
						} else if (err['status'] == 403) {
							this.messageService.showMessage({ content: 'Not licensed', type: MessageType.error });
						} else {
							this.messageService.showMessage({ content: err['error'], type: MessageType.error });
						}
					})
					.finally(() => {
						this.loadingService.busyNo();
					});
			}
		} else {
			this.messageService.showMessage({ content: 'devicesSelectionError', type: MessageType.error });
		}
	}

	public async operationHandler({ operation, dataItem, selection }, type: string): Promise<void> {
		switch (operation) {
			case 'dblClick':
				var buUnits = await this.service.getAllBusUnits(true).toPromise()
					.then(async (resp) => {
						var arr = [];
						resp.forEach(el => {
							arr.push(el['busUnitName']);
						});

						console.log(arr);

						this.formCV.Tabs[0].Groups[0].Fields[1].data = arr;
					})
					.catch((err) => {
						this.messageService.showMessage({ content: err.message, type: MessageType.error });
					});

				switch (type) {
					case 'type_firmDevices':
						this.selectedFirmDevice = dataItem;
						this.type = 0;
						this.openDialog(this.selectedFirmDevice.deviceDescription);

						break;
					case 'type_signDevices':
						this.selectedSignDevice = dataItem;
						this.type = 1;
						this.openDialog();

						break;
					case 'type_firmSignDevices':
						this.selectedFirmSignDevice = dataItem;
						break;
				}
				break;
			case 'selectionChanged':
				//shrani trenutno izbrano napravo v tabeli
				if (selection.length == 0) {
					await this.getData();
					switch (type) {
						case 'type_firmDevices':
							this.selectedFirmDevice = null;
							break;
						case 'type_signDevices':
							this.selectedSignDevice = null;
							break;
						case 'type_firmSignDevices':
							this.selectedFirmSignDevice = null;
							break;
					}
				} else {
					switch (type) {
						case 'type_firmDevices':
							this.selectedFirmDevice = dataItem;
							break;
						case 'type_signDevices':
							this.selectedSignDevice = dataItem;
							break;
						case 'type_firmSignDevices':
							//prikaže povezana firmDevice in singDevice
							this.selectedFirmSignDevice = dataItem;

							await this.getData(true, true, false);

							this.signDevices = this.signDevices.filter(el => el.rowGuidSignDevice == this.selectedFirmSignDevice.rowGuidSignDevice);
							this.firmDevices = this.firmDevices.filter(el => el.firmDeviceId == this.selectedFirmSignDevice.firmDeviceId);

							break;
					}
				}
				break;
			case 'remove':
				this.loadingService.busyYes();
				if (type === "type_firmSignDevices") {
					//brisanje povezave med napravama
					await this.service.deleteFirmSignDevice(dataItem['firmSignDeviceId']).toPromise()
						.then(async () => {
							var str = await this.translate.get('connectionRemoved').toPromise()
							var msg = StringFromat(str, dataItem['firmDeviceName'], dataItem['signDeviceName']);

							this.messageService.showMessage({ content: msg, type: MessageType.info })
							this.getData();
						}).catch((err) => {
							this.loadingService.busyNo();
							this.messageService.showMessage({ content: "errDeletingConnection", type: MessageType.error });
						});
				}
				else if (type === "type_signDevices") {
					//brisanje podpisne naprave če ni povezana					
					await this.service.deactivateSignDeviceLicense(dataItem['rowGuidSignDevice']).toPromise() // deaktiviram licenco podpisne naprave
						.then(async () => {
							await this.service.deleteSignDevice(dataItem['rowGuidSignDevice']).toPromise() // izbiršem podpisno napravo
								.then(async () => {
									var str = await this.translate.get("deviceRemoved").toPromise();
									var msg = StringFromat(str, dataItem['deviceName']);

									this.messageService.showMessage({ content: msg, type: MessageType.info });
									await this.getData();
								}).catch((err) => {
									this.loadingService.busyNo();
									this.messageService.showMessage({ content: "errDeletingDevice", type: MessageType.error });
								});
						})
						.catch((err) => {
							this.loadingService.busyNo();
							this.messageService.showMessage({ content: "errDeactivateSignDeviceLicense", type: MessageType.error });
						});
				}
				else if (type === "type_firmDevices") {
					//brisanje firmDevica če ni povezan
					await this.service.deleteFirmDevice(dataItem['firmDeviceId']).toPromise()
						.then(async () => {
							var str = await this.translate.get("deviceRemoved").toPromise();
							var msg = StringFromat(str, dataItem['deviceName']);

							this.messageService.showMessage({ content: msg, type: MessageType.info });
							await this.getData();
						}).catch((err) => {
							this.loadingService.busyNo();
							this.messageService.showMessage({ content: "errDeletingDevice", type: MessageType.error });
						});
				}
				this.loadingService.busyNo();
				break;
		}
	}

	public onResize(event) {
		this.signDeviceGridSettings.height = (window.innerHeight - AppConfig.settings.headerHeight) * 0.4;
		this.firmDeviceGridSettings.height = (window.innerHeight - AppConfig.settings.headerHeight) * 0.4;
		this.firmSignDeviceGridSettings.height = (window.innerHeight - AppConfig.settings.headerHeight) * 0.45;
	}

	public openDialog(description?: string) {
		if (description != undefined) {
			this.devDescriptionData = description;
			this.selectedBusUnit = this.selectedFirmDevice.busUnitId;
			this.showDescription = true;
		} else {
			this.selectedBusUnit = this.selectedSignDevice.busUnitId;
			this.showDescription = false;
		}

		this.deviceDialogVisible = true;
	}

	public closeDialog() {
		this.deviceDialogVisible = false;
	}

	public saveDeviceData() {
		this.loadingService.busyYes();

		if (this.type == 0) {
			this.selectedFirmDevice.busUnitId = this.selectedBusUnit;
			this.selectedFirmDevice.deviceDescription = this.devDescriptionData;
			this.selectedFirmDevice.busUnitName = this.busUnits.find(x => x.value == this.selectedBusUnit).label;

			this.service.updateFirmDevice(this.selectedFirmDevice).toPromise()
				.then(_ => {
					this.deviceDialogVisible = false;

					this.messageService.showMessage({ content: 'busUnitFirmDeviceEdited', type: MessageType.info });
				});
		}
		else if (this.type == 1) {
			this.selectedSignDevice.busUnitId = this.selectedBusUnit;
			this.selectedSignDevice.busUnitName = this.busUnits.find(x => x.value == this.selectedBusUnit).label;

			this.service.updateSignDevice(this.selectedSignDevice).toPromise()
				.then(_ => {
					this.deviceDialogVisible = false;

					this.messageService.showMessage({ content: 'busUnitSignDeviceEdited', type: MessageType.info });
				});
		}

		this.loadingService.busyNo();
	}
}