import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectableSettings, RowArgs } from '@progress/kendo-angular-grid';
import { GridComponentView } from '../GridComponentView';
import { ComponentViewDTO, ComponentFieldDTO } from '../../../Common/ComponentViewDTO';

@Component({
	selector: 'app-grid-v2-field-selection-dialog',
	templateUrl: './grid-v2-field-selection-dialog.component.html',
	styleUrls: ['./grid-v2-field-selection-dialog.component.css']
})
export class GridV2FieldSelectionDialogComponent implements OnInit {

	@Input() gridsettings: GridComponentView;

	@Output() gridFieldsDialogCallback: EventEmitter<ComponentViewDTO> = new EventEmitter();

	public shownFields: ComponentFieldDTO[] = [];
	public availableFields: ComponentFieldDTO[] = [];

	public userGridColumnSettings: any[];

	public selectableSettingsShown: SelectableSettings = {
		enabled: true,
		mode: 'multiple'
	};

	public selectableSettingsAvailable: SelectableSettings = {
		enabled: true,
		mode: 'multiple'
	};

	public selectionAvailable: any[] = [];
	public selectionShown: any[] = [];

	public availableSelect: string = "FieldName";
	public shownSelect: string = "FieldName";

	constructor() {
	}

	ngOnInit() {
		//Pridobim polja, ki si jih je uporabnik poklikal za prikaz
		this.shownFields = this.gridsettings.Fields.filter(x => x.FieldVisible);


		//this.shownFields = JSON.parse(localStorage.getItem(this.gridsettings.state + '_shownFields'));

		if (this.shownFields == undefined) {
			this.shownFields = this.gridsettings.Fields;
			this.availableFields = [];
		}
		else {
			this.availableFields = this.gridsettings.Fields.filter(x => this.shownFields.map(y => y.FieldName).includes(x.FieldName) == false);
		}
	}

	/** Funkcija se sproži ob vsakem kliku na vrstico/celico tabele, spremeni lastnost checkboxa na nasprotno kot je bilo prej */
	public cellClickHandler({ isEdited, dataItem, rowIndex }): void {
		dataItem.FieldSelected = !dataItem.FieldSelected;
	}

	public changeColumns(action: string) {
		if (action == 'moveRightOne') { //samo izbrane dodamo med prikazane
			for (let selection of this.selectionAvailable) {
				let selectedItemIndex = this.availableFields.findIndex(x => x.FieldName == selection);
				this.shownFields.push(this.availableFields[selectedItemIndex]);
				this.availableFields.splice(selectedItemIndex, 1);
			}
		}
		else if (action == 'moveRightAll') { //vse dodamo med prikazane
			this.shownFields = this.shownFields.concat(this.availableFields);
			this.availableFields = [];
		}
		else if (action == 'moveLeftOne') { //samo izbrane dodamo med skrite
			for (let selection of this.selectionShown) {
				let selectedItemIndex = this.shownFields.findIndex(x => x.FieldName == selection);
				this.availableFields.push(this.shownFields[selectedItemIndex]);
				this.shownFields.splice(selectedItemIndex, 1);
			}
		}
		else if (action == 'moveLeftAll') { //vse dodamo med skrite
			this.availableFields = this.availableFields.concat(this.shownFields);
			this.shownFields = [];
		}

		this.selectionAvailable = [];
		this.selectionShown = [];
	}

	public saveSettings() {
		for (let gSetting of this.gridsettings.Fields) {
			gSetting.FieldVisible = false;
		}

		for (let sField of this.shownFields) {
			for (let col of this.gridsettings.Fields) {
				if (col.FieldName == sField.FieldName) {
					col.FieldVisible = true;
				}
			}
		}
		this.gridFieldsDialogCallback.emit(this.gridsettings);
	}
}
