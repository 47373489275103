import { ComponentViewDTO } from '../../Core/Common/ComponentViewDTO';

export const UnitsComponentView: ComponentViewDTO = {
    ComponentType: 1,
    ComponentName: 'ActiveUnitsGridView',
    Tabs: [
        {
            TabName: '',
            Groups: [
                {
                    GroupName: '',
                    CSSStyle: '',
                    Fields: [
                        { FieldName: 'busUnitName', FieldType: 'input' },
                        { FieldName: 'createdAt', FieldType: 'dateTime' },
                        { FieldName: 'activeText', FieldType: 'input' }
                    ]
                }
            ]
        }
    ]
    ,
    Add: false,
    Edit: true,
    Delete: true,
    EnableExport: true,
    isSelectable: false,
    pageable: true
};