import { Component, HostListener, OnInit } from '@angular/core';
import { dtoDocument } from 'app/common/dtos/dtoDocument';
import { ComponentViewDTO } from 'app/Core/Common/ComponentViewDTO';
import { LookupItem } from 'app/Core/Components/grid-v2/LookupItem';
import { LoadingService } from 'app/Core/CoreServices/loading-services/loading.service';
import { MessageService } from 'app/Core/CoreServices/message-services/message.service';
import { DataEditService } from 'app/services/dataEditService/data-edit.service';
import { DocComponentView } from './docGridSettings';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'app/services/loginService/login.service';
import { AppConfig } from 'app/Core/CoreServices/configuration-services/configuration.service';
import { MessageType } from 'app/Core/CoreServices/message-services/message';
import { StringFromat } from 'app/services/helperFunctions/functions';
import { dtoSignDevice } from 'app/common/dtos/dtoSignDevice';
import { GroupDescriptor } from '@progress/kendo-data-query';

@Component({
    selector: 'app-postponed-documents',
    templateUrl: './postponed-documents.component.html',
    styleUrls: ['./postponed-documents.component.css']
})
export class PostponedDocumentsComponent implements OnInit {

    public searchAlias: string = "";
    public docs: dtoDocument[] = [];
    public currDoc: dtoDocument = undefined;

    public customerData: LookupItem[] = [];
    public erpKeyData: LookupItem[] = [];
    public docGroupData: LookupItem[] = [];
    public signDevicesData: dtoSignDevice[] = [];

    public selectedCustomer: any = -1;
    public selectedErpKey: any = -1;
    public selectedDocGroup: any = -1;

    public docGridSettings: ComponentViewDTO = DocComponentView;
    public docFilesDialogVisible: boolean = false;

    public selectedDocument: dtoDocument;

    public showSignDevices: boolean = false;
    public selectedSignDevice: string;

    public curSubscriber: string;

    constructor(private service: DataEditService, private loadingService: LoadingService,
        private messageService: MessageService, private translate: TranslateService, private loginService: LoginService) { }

    async ngOnInit() {
        if (Date.parse(this.loginService.user.tokenValidTo.toString()) < new Date(Date.now()).getTime()) {
            this.loginService.doLoginFromSessionStorage();
        }

        await this.getFilterData();
        await this.getData();
        await this.getSignDevices();

        this.docGridSettings.height = (window.innerHeight - AppConfig.settings.headerHeight) * 0.8;

        if (this.loginService.user.isAdmin) {
            this.docGridSettings.Delete = true;
        }

        // dobim id input elementa in mu nastavim autofocus ko se stran nalozi
        var inputs = document.getElementById('erpDropDown').getElementsByTagName('input');
        $("#" + inputs[0].id).val("");
        document.getElementById(inputs[0].id).focus();
    }

    public async getFilterData() {
        await this.service.getPostponedFilterData().toPromise()
            .then(async res => {
                this.customerData = [];
                this.customerData.push({ label: await this.translate.get("all").toPromise(), value: -1 });
                res['customers'].forEach(el => {
                    this.customerData.push({ label: el, value: el });
                });

                this.erpKeyData = [];
                this.erpKeyData.push({ label: await this.translate.get("all").toPromise(), value: -1 });
                res['erpKeys'].forEach(el => {
                    this.erpKeyData.push({ label: el, value: el });
                });

                this.docGroupData = [];
                this.docGroupData.push({ label: await this.translate.get("all").toPromise(), value: -1 });
                this.docGroupData.push({ label: "Sign", value: "Sign" });
                res['docGroups'].forEach(el => {
                    this.docGroupData.push({ label: el, value: el });
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    public async getData() {
        try {
            this.loadingService.busyYes();

            if (Date.parse(this.loginService.user.tokenValidTo.toString()) < new Date(Date.now()).getTime()) {
                await this.loginService.doLoginFromSessionStorage();
            }

            await this.service.getPostponedDocumentsInBranch().toPromise()
                .then((res) => {
                    this.docs = res;

                    this.docs.forEach(el => {
                        if (el.docGroup == undefined || el.docGroup.trim() == "") {
                            el.docGroup = "Sign";
                        }
                    })
                });

            this.loadingService.busyNo();
        }
        catch (err) {
            this.loadingService.busyNo();
        }
    }

    public async getSignDevices() {
        try {
            this.service.getAllSignDevicesInBusUnit().toPromise()
                .then((res) => {
                    this.signDevicesData = res;
                });
        }
        catch (err) { }
    }

    public async operationHandler({ operation, dataItem, selection }): Promise<void> {
        switch (operation) {
            case 'selectionChanged': {
                this.selectedDocument = dataItem;
                break;
            }
            case 'remove': {
                await this.service.deleteDocument(dataItem['docId']).toPromise()
                    .then(async () => {
                        var str = await this.translate.get('docRemoved').toPromise();
                        var msg: string = StringFromat(str, dataItem['docId'], dataItem['erpkey'])

                        this.messageService.showMessage({ content: msg, type: MessageType.info });
                    })
                    .catch((err) => {
                        this.messageService.showMessage({ content: 'error', type: MessageType.error });
                    })
                    .finally(() => {
                        this.getData();

                        this.selectedCustomer = -1;
                        this.selectedErpKey = -1;
                        this.selectedDocGroup = -1;
                    });
                break;
            }
            case 'dblClick': {
                this.curSubscriber = this.selectedDocument.subscriber;

                // if (localStorage.getItem("auto_send") && localStorage.getItem("auto_send") == "true") {
                //     console.log("pošlji avtomatsko");
                //     this.sendToDefaultDevice();
                // } else {
                //     this.selectDeviceToSend();
                // }

                this.selectDeviceToSend();
            }
        }
    }

    /**Filtrira tabelo dokumentov glede na izbran status*/
    public async filterData() {
        await this.getData().then(() => {
            if (this.selectedCustomer != -1) {
                this.docs = this.docs.filter(el => el['customerTitle'] === this.selectedCustomer);
            }
            //if (this.selectedErpKey !== -1) {
            if (this.selectedErpKey !== -1 && this.selectedErpKey !== undefined) {
                this.docs = this.docs.filter(el => el['erpkey'] === this.selectedErpKey);
            }

            if (this.selectedDocGroup !== -1) {
                this.docs = this.docs.filter(el => el['docGroup'] === this.selectedDocGroup);
            }
        }).catch((err) => {
            this.messageService.showMessage({ content: "errorFilterDocuments", type: MessageType.error });
        });
    }

    public async refresh() {
        await this.getFilterData();

        this.getData();

        this.selectedCustomer = -1;
        this.selectedErpKey = -1;
        this.selectedDocGroup = -1;
    }

    public onResize(event) {
        this.docGridSettings.height = (window.innerHeight - AppConfig.settings.headerHeight) * 0.8;
    }

    public closeDialog() {
        this.showSignDevices = false;
    }

    public async sendToDefaultDevice() {
        await this.service.sendPostponedDocument(this.selectedDocument.rowGuidSignDevice, this.selectedDocument).toPromise()
            .then(async (res) => {
                var str = await this.translate.get('postponedDocSent').toPromise();
                var msg: string = StringFromat(str, res['erpKey'])

                this.messageService.showMessage({ content: msg, type: MessageType.info });

                this.getData();

                this.getFilterData();
            })
            .catch((er) => {
                console.log(er);

                this.messageService.showMessage({ content: er.message, type: MessageType.error });
            })
            .finally(() => {
                this.loadingService.busyNo();
                this.showSignDevices = false;
                this.selectedErpKey = -1;
            });
    }

    public async selectDeviceToSend() {
        this.signDevicesData.sort((a: dtoSignDevice, b: dtoSignDevice) => (b.rowGuidSignDevice === this.selectedDocument.rowGuidSignDevice ? 1 : -1));

        this.showSignDevices = true;
        this.selectedSignDevice = this.selectedDocument.rowGuidSignDevice;
    }

    public async deviceClick(uuid: string) {
        this.loadingService.busyYes();

        this.selectedDocument.subscriber = this.curSubscriber;

        await this.service.sendPostponedDocument(uuid, this.selectedDocument).toPromise()
            .then(async (res) => {
                var str = await this.translate.get('postponedDocSent').toPromise();
                var msg: string = StringFromat(str, res['erpKey'])

                this.messageService.showMessage({ content: msg, type: MessageType.info });

                this.getData();

                this.getFilterData();
            })
            .catch((er) => {
                console.log(er);

                this.messageService.showMessage({ content: er.message, type: MessageType.error });
            })
            .finally(() => {
                this.loadingService.busyNo();
                this.showSignDevices = false;
                this.selectedErpKey = -1;
            });
    }

    public erpKeyFocus(e: any) {
        try {
            $("#" + e.target.id).val("");
        } catch (e) { }
    }

    public onKeyUpErp(e) {
        //this.keyHandler(e);
    }

    @HostListener('window:keydown.enter', ['$event'])
    keyEvent(event: KeyboardEvent) {
        this.keyHandler(event);
    }

    private keyHandler(event: any) {
        if (event.key.toLowerCase() == "enter") {
            if (this.docs.length == 1) {
                if (localStorage.getItem("auto_send") && localStorage.getItem("auto_send") == "true") {
                    this.selectedDocument = this.docs[0];
                    console.log(this.selectedDocument);

                    this.sendToDefaultDevice();
                }
            }
        }
    }
}