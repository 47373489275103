import { ComponentViewDTO } from "../../Core/Common/ComponentViewDTO";

export const FirmSignDevicesGridSettings: ComponentViewDTO = {
    ComponentType: 1,
    ComponentName: "FirmSignDevicesGridView",
    Tabs: [
        {
            TabName: '',
            Groups: [
                {
                    GroupName: '',
                    CSSStyle: '',
                    Fields: [
                        { FieldName: "firmDeviceName", FieldType: 'input' },
                        { FieldName: "signDeviceName", FieldType: 'input' },
                        { FieldName: "connectedBy", FieldType: 'input' },
                        { FieldName: "createdAt", FieldType: 'dateTime' }
                    ]
                }
            ]
        }
    ],
    Add: false,
    Edit: true,
    Delete: true,
    EnableExport: true,
    isSelectable: true,
    pageable: true,
    //showCheckboxColumn: true
};