import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { msgDialogParam } from '../../Components/msg-dialog/msg-dialog.component';
import { Message, MessageType } from './message';
import { DialogResultTypes } from '../../Components/msg-dialog/dialog-result-types.enum';
import { ResponseMsgWCode, BaseClass } from '../../Common/BaseClass';

@Injectable()
export class MessageService {

    constructor(private toastService: ToastrService, private translate: TranslateService) {
    }

    public async showMessage(message: Message) {
        // Pridobim prevedeno sporočilo
        const text = await this.translate.get(message.content).toPromise();

        switch (message.type) {
            case MessageType.error:
                this.toastService.error(text);
                break;
            case MessageType.success:
                this.toastService.success(text);
                break;
            case MessageType.info:
                this.toastService.info(text);
                break;
            case MessageType.warn:
                this.toastService.warning(text);
                break;
        }
    }

    //#region Popup dialog za info, napake ter vprašanja

    public msgDialog: msgDialogParam = undefined;
    public msgResultObservable = new Subject();

    public async msgDialogResultEvent(result: DialogResultTypes) {
        this.msgResultObservable.next(result);
        this.msgDialog = undefined
    }

    public showDialog(param: msgDialogParam): Promise<DialogResultTypes> {
        this.msgDialog = param;
        return new Promise<any>((resolve, reject) => {
            this.msgResultObservable.subscribe(_res => {
                resolve(_res);
            })
        });
    }

    //#endregion


    /**
     * Generalna funkcija za prikaz objekta "ResponseMsgWCode" --> Ponavadi uporabljen, ko je success false in vsebuje content
     * @param msg
     */
    public async showResponseMsgWCode(msg: ResponseMsgWCode) {
        if (msg.Success == false) {
            let message = msg.ErrorMsg != undefined ? msg.ErrorMsg : (msg.ErrorMessage != undefined ? msg.ErrorMessage : 'operationNotSucessfull');
            this.showMessage({ content: message, type: MessageType.error });
        }
        else {
            this.showMessage({ content: 'operationSucessfull', type: MessageType.success });
        }
    }

    /**
     * Generalna funkcija za prikaz objekta "BaseClass" --> Ponavadi uporabljen, ko je success false in vsebuje content
     * @param msg
     */
    public async showResponseBaseClassMsg(bc: BaseClass) {
        if (bc.Success == false && bc.ErrorMessage != undefined) {
            this.showMessage({ content: bc.ErrorMessage, type: MessageType.error });
        }
        else if (bc.Success == false) {
            this.showMessage({ content: "Prišlo je do napake", type: MessageType.error });
        }
        else if (bc.Success == true) {
            this.showMessage({ content: "Operacija uspešno zaključena.", type: MessageType.success });
        }

    }


    /*// to append in body
    private show(text: string, type: string): void {
      this.notify.show(text, { position:'top', duration:'2000', type: 'success' });
    }
  
    // to append in any other component.
    private showModular(text: string, type: string): void {
        this.notify.show(text, { position:'top', duration:'2000', type: 'success', location: '#modular' });
    }*/

}
